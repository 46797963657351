

.div_login_m>a {
	text-decoration: underline;
	color: rgb(64, 154, 161);
	font-size: 18px;
	display: block;
	padding: 15px 12px;
	border-top: 1px dashed;
	border-color: var(--gris-ine)
}

#log_img img{
  width: 220px;
  height: auto;
}

.div_login_m>* {
	width: 300px;
	text-align: center;
}

#etiqueta-bienvenida{
  color:#333333;
  font-size: 24px;
  margin-top:25px;
	margin-bottom: 10px;
	font-family: Roboto-Bold;
}

#etiqueta-ingresar{
  color:##6D6D6D;
  font-size: 14px;
	font-family: Roboto-Regular;
}

#registrate-login{
  color:#625eb0;
  font-size: 14px;
  margin-bottom: 5px;
}

#btn-sesion{
  background-color: #D3097F;
  border-radius: 6px;
	opacity: 1;
	width: 252px;
	height: 35px;
}
#usr-div span,
#pws-div span{
		color:#D3097F;
}

#usr-div .ant-form-item-control-input,
#pws-div .ant-form-item-control-input {
	height: 35px;
	width: 252px;
}

#usr-div .ant-input-affix-wrapper,
#pws-div .ant-input-affix-wrapper {
	border-radius: 15px;
}
#link-aqui{
  color:#6D6D6D;
	font-family: Roboto-Italic;
	font-size: 10px;
}

.modalLogin .ant-modal-content {
    border-radius: 12px;
    width: 336px;
    height: 511px;
		margin-left: 80px;
}
.modalLogin .ant-modal-footer{
	display: none;
}
#derechos{
	color: #6D6D6D;
	font-size: 9px;
	font-family: Roboto-Regular;
}
#error{
	color: red;
	font-size: 12px;
	font-family: Roboto-Regular;
}
#btn-submit-div .ant-row .ant-form-item{
	margin-bottom: 16px;
}
#pws-div .ant-row .ant-form-item{
	margin-bottom: 10px;
}

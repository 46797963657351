.menu-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  row-gap: 5px;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  z-index: 1000;
  height: 50px;
  width: 100%;
  margin-top: 99px;
  background-color: #FFFFFF;
  box-shadow: 8px 9px 6px #DAE1E0;
  padding: 5px 50px 0px 50px;

  @media (max-width: 700px) {
    align-items: center;
    padding: 0px;
  }

  .mc-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 5px;
    border-radius: 20px;
    padding: 8px 8px 0px 8px;

    .mc-item-img {
      width: 22px;
      height: auto;
      fill: #6D6D6D;
      margin-bottom: 5px;
    }

    .mc-item-txt {
      font-family: Roboto-Bold;
      font-size: 15px;
      color: #6D6D6D;
      margin-bottom: 5px;

      @media (max-width: 800px) {
        font-size: 10px;
      }

      @media (max-width: 600px) {
        display: none;
      }

    }

    &:hover {
      background-color: #F2F1EE;
    }
  }

  .mc-item-selected {
    .mc-item-img {
      filter: invert(13%) sepia(79%) saturate(4905%) hue-rotate(314deg) brightness(80%) contrast(101%);
    }
    .mc-item-txt {
      color: var(--rosa-ine);
      border-bottom: 3px solid var(--rosa-ine);
    }
  }

  .mc-item-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }

}


.tituloTable{
font-size: 26px;
color: var(--unnamed-color-7c5ba2);
text-align: left;
font-family: 'RobotoSlab-Regular';
letter-spacing: 0px;
color: #7C5BA2;
opacity: 1;
}
.tableStyle{
	border: 0.5px solid #999999;
}
.tableStyleVoluntarios{
	border: 0.5px solid #999999;
	text-align: center;
	opacity: 1;
	width: 550px;
	height: 100%;
}
#pagina{
	width: 550px;
}
.InputFilter{
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 0.5px solid #999999;
	opacity: 1;
	width: 145px!important;
	/*height: 20px!important;*/
}
.nameColum{
  font-family: 'RobotoSlab-Regular';
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-333333);
text-align: center;
letter-spacing: 0px;
color: #333333;
opacity: 1;
}
.subTitulo{
  font-family: 'RobotoSlab-Regular';
  color: #6D6D6D;
  font-size: 20px;
}
.paginationStyle{
	display: flex;
    margin: 0 auto;
    justify-content: center;
    padding-top: 15px;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.rowVoluntarios{
	margin-top: -1% !important;
	margin-left: -20% !important;
}
.tituloTableVoluntarios{
	font-size: 15px!important;
	color: #333333!important;
	font-family: 'RobotoSlab-Regular';
	display: block;
		margin-top: 16px;
	margin-bottom: 16px;
}
.anticon-question-circle{
	color:#663C99;
}
#icon-asignar span svg {
    color: #663C99;
}
table.tableStyleVoluntarios > tbody > tr > td{
    padding: 8px;
}
table.tableStyleVoluntarios > thead > th {
    padding: 8px;
}
.btonGuardar{
	background-color: #D3097F;
	color: #FFFFFF;
	border-radius: 5px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
}
#img-modal{
	width: 230px;
	height: 210px;
	position: relative;
	text-align: center;
	right: -30px;
}
.titulo-modal{
	font-family: 'RobotoSlab-Light';
	font-size: 33px;
	margin: auto;
	margin-top: -20px;
}
.notificacion-modal{
	font-family: 'RobotoSlab-Regular';
	font-size: 18px;
	color: #575756;
	margin: auto;
}
.modal-Voluntarios{
	padding: 0px;
  height: 407px;
  /*background-image: url('../../assets/img/fondo_gafete.png');*/
  background-size: cover;
}
.modal-Voluntarios .ant-modal-content {
    height: 505px!important;
}
#folio-casilla{
	text-align: center;
}

  #guardar-zor:hover{
	background-color: #F4F3F4;
  }
  #guardar-zor:hover span{
	color: #6D6D6D;
  }
  #guardar-zor span{
	color: #fff;
	font-size: 14px;
	font-family: "Roboto-Medium";
  }
  .encabezadoTabla{
  	font-family: 'Roboto-Bold';
	font-size: 15px;
  }
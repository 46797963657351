div.ant-steps {
    margin: 0 auto 30px;
    width: 100%;
}

.ant-steps-item.ant-steps-item-,
.ant-steps-item.ant-steps-item-wait,
.ant-steps-item.ant-steps-item-finish {
    overflow: visible;
}

@media (min-width: 481px) {
    .ant-steps-item-description {
        position: relative;
        left: calc(-50% + -35px);
        top: 3.8em;
        z-index: 1;
        width: 100%;
        margin: auto;
        font-family: 'Roboto-Light';
    }

    .ant-steps.ant-steps-horizontal div.ant-steps-item-content {
        width: calc(100% + -40px);
        text-align: center;
        display: inline-block;
        border-bottom: solid 2px var(--gris-ine-guia);
    }

    .ant-steps.ant-steps-horizontal div.ant-steps-item-content .ant-steps-item-description {
        height: 20px;
    }

    .ant-steps.ant-steps-horizontal .ant-steps-item:last-child div.ant-steps-item-content .ant-steps-item-description {
        left: -80px;
        width: 100px;
        display: block;
    }

    .ant-steps.ant-steps-horizontal .ant-steps-item:last-child div.ant-steps-item-content {
        width: 0;
        overflow: visible;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        margin-right: 0px;
        white-space: nowrap;
    }
}

.ant-steps-item-icon{
    background-color:  var(--rosa-ine);
    border-color:var(--rosa-ine);
    color: white;
    margin-right: 0px;
}

.ant-steps-item-icon > 
.ant-steps-icon{
    color: rgb(255, 255, 255);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: var(--rosa-ine);
}

.ant-steps-item-description {
	font-size: 14px;
	font-family: 'Roboto-Light';
	color: black;
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: var(--gris-ine-guia);
    border-color: var(--gris-ine-guia);
}

.ant-steps-item-icon {
    width: 70px;
    height: 70px;
    line-height: 4;
}

.ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: white;
}

.ant-steps-icon {
    vertical-align: middle;
    font-size: 2em;
    font-family: 'Roboto-Regular';
}

.ant-steps-item-title {
    margin-top: 15px;
    display: block;
}

.ant-steps-item-title::after {
    height: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-right: 0;
}

.ant-steps-item-finish .ant-steps-item-icon {
    border-color: var(--rosa-ine);
}

#img-guia{
    width: 70px;
    height: 70px;
}

@media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
        left: 20px;
        width: 2px;
        padding: 40px 0 0;
    }

    .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
        width: 2px;
    }

    .ant-steps-item-icon {
         width: 40px;
         height: 40px;
         line-height: 2;
    }
    #img-guia{
        width: 40px;
        height: 40px;
    }
}
@import url('../styles.css');

.ant-checkbox-wrapper {
    line-height: 1em;
    text-align: left;
    color: var(--negro_SUPyCAP);
}

.ant-checkbox-wrapper .ant-checkbox-inner {
    border-color: var(--gris-ine);
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
.ant-checkbox-wrapper:focus-within .ant-checkbox-inner,
.ant-checkbox-checked::after {
    border-color: var(--rosa-ine);
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--rosa-ine);
}

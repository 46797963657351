@import url('../styles.css');

#infografia{
    width: 70%;
    height: 60%;
}
#infoDocs{
    display: inline-block;
    position: relative;
}

#buttonPlatica{
    position: absolute;
    bottom:2em;
    right:23em;
    border-radius: 15px;
    text-align: center;
    margin: 4px 2px;
}

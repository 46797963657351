#seccion{
    margin-bottom: 25px;
}

#titulo-componente{
    color: #8c7ba2;
    font-size: 26px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-family: 'Roboto-Regular';
}

#tabla-consulta-casillas{
    text-align: center;
}

#edit-icon svg,
#deleted-icon svg,
#eyecc-icon svg{
  color:#494845;

}

#filter-icon svg{
    color:#663C99;
}

#modal-header{
    font-family: 'Roboto-Regular';
    background-color: #fff;
    text-align: justify;
}

#modal-header h1{
    font-family: 'RobotoSlab-Bold';
    color: #7C5BA2;
    margin-top: 30px;
    margin-left: 10px;
    font-size: 30px;
}

#body-modal{
    font-family: 'Roboto-Regular';
    color:#333333;
    margin-left: 10px;
    margin-top: 25px;
}

.titulo-exitoConvocatoria{
    font-size: 25px;
    font-family: Roboto-Light;
    color:#1D1D1B;
    text-align: center;
}
  
.notificacion-exitoConvocatoria{
    font-size: 18px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Regular;
    color:#575756;
    
}

.modalConvocatoria .ant-modal-content{
    text-align: center;
    width: 327px;
    height: 470px;
}
  

#img-exitoConvocatoria-modal{
    width: 230px;
    height: 210px;
}


.convocatoriaStyle{
    font-size: 20px;
    text-align: center;
    justify-content:center;
    font-family: RobotoSlab-Regular;
    color:#6D6D6D;
    
}

/*.ant-btn-primary{
    background-color: #D3097F;
    width: 118px;
}*/

.div-boton button.ant-btn{
    color:#FFFFFF;
	background-color: #D3097F;
    
}

.div-boton button.ant-btn:hover{
    color:#FFFFFF;
	background-color: #D3097F;
}

.div-boton button.ant-btn:disabled{
	background-color:#F4F3F4;
    color:#6D6D6D;
}


.tituloTable{
	color: #7C5BA2!important;
	font-family: 'RobotoSlab-Regular';
	font-size: 26px;
	letter-spacing: 0px;
	opacity: 1;
}
.subTitulo{
	color: #726E69!important;
	font-family: 'RobotoSlab-Regular';
	font-size: 24px;
	letter-spacing: 0px;
	opacity: 1;
}
.tableHeadCapacitacion{
	background: #D3097F;
	width: 100%;
	font-size: 15px !important;
	color: #FFFFFF !important;
	font-family: 'RobotoSlab-Regular';
	display: block;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-top: 10px;
	min-width: 100%;
}
#guardar-asistencia{	
	margin-bottom: 20px;
	margin-top: 45px;
	background-color: #D3097F;
	font-family: 'RobotoSlab-Regular';
	border-radius: 10px;
	width: 118px;
	height: 34px;
  }

  #guardar-asistencia:hover{
	background-color: #F4F3F4;
	font-family: 'RobotoSlab-Regular';
  }
  #guardar-asistencia:hover span{
	color: #6D6D6D;
	font-family: 'RobotoSlab-Regular';
  }
  #guardar-asistencia span{
	color: #fff;
	font-size: 14px;
	font-family: 'RobotoSlab-Regular';
  }
  #icon-asignar span svg {
    color: #7C5BA2;
    width: 15px;
    height: 15px;
}
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    font-family: 'RobotoSlab-Regular';
}
.titulo-modal{
	margin-top: 15px;
	font-size: 28px;
	font-family: 'RobotoSlab-Regular';
}
#folio-casilla{
	margin-bottom: -15px;
	font-family: 'RobotoSlab-Regular';
}
table.tableStyleVoluntarios1 > tbody > tr > td{
    padding: 8px;
    font-family: 'RobotoSlab-Regular';
}
table.tableStyleVoluntarios1 > thead > th {
    padding: 8px;
    font-family: 'RobotoSlab-Regular';
}
.tableStyleVoluntarios1{
	border: 0.5px solid #999999;
	text-align: center;
	opacity: 1;
	width: 100%!important;
	height: 100%;
	font-family: 'RobotoSlab-Regular';
}
.ant-modal-footer {
    text-align: center;
}
.ant-btn{
	border: 2px solid #868686;
	opacity: 1;
	color: #868686;
	font-family: 'RobotoSlab-Regular';
}
.ant-btn-primary{
	border: 2px solid var(--rosa-ine-alph) !important;
	color: #FFFFFF;
	font-family: 'RobotoSlab-Regular';
}
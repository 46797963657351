.mc-filtros {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
    position: fixed;
    z-index: 900;
    column-gap: 20px;
    row-gap: 10px;
    align-content: center;
    justify-content: flex-start;
    margin-top: 50px;
    background-color: #333333;
    padding-left: 150px;
    padding-bottom: 5px;

    .mc-filtros-combo {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: 10px;
        row-gap: 5px;

        .ant-select .ant-select-selector {
            min-width: 200px;
            text-align: center;
            border-radius: 5px;
            background-color: #333333;
            color: #FFFFFF;
            font-family: 'Roboto-Regular';
            font-size: 13px;
        }

        .ant-select-focused .ant-select-selector,
        .ant-select-selector:focus,
        .ant-select-selector:active,
        .ant-select-selector:hover,
        .ant-select-open .ant-select-selector {
            border-color: #929292 !important;
            box-shadow: none !important;
        }

        .anticon svg {
            color: #fff;
          }

        .mc-filtros-combo-text{
            width: 100%;
            text-align: center;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 14px;
            border-left: 2px solid #929292;
            padding-left: 10px;
        }
    }

    .mc-filtros-combo-estado {
      min-width: 200px;
    }

    .mc-filtros-combo-distrito {
      min-width: 250px;
    }

}

@media (max-width: 750px) {
    .mc-filtros {
		padding-left: 10px;
		padding-right: 10px;

        .mc-filtros-combo {
            .mc-filtros-combo-text{
                display: none;
            }
        }
	}
}

@media (max-width: 500px) {
    .mc-filtros {
        column-gap: 5px;
		padding: 2px;
        justify-content: space-between;

        .mc-filtros-combo {

            .ant-select .ant-select-selector {
                width: 100%;
                min-width: 130px;
            }

        }

        .mc-filtros-combo-estado {
            width: 100%;
            min-width: 130px;
        }
      
        .mc-filtros-combo-distrito {
            width: 100%;
            min-width: 130px;
        }
    }

}
.publicador-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    min-height: calc(100vh - 250px);
    margin: 160px 80px 80px 80px;
    background-color: #FFFFFF;
    padding: 30px 75px 30px 75px;

    @media (max-width: 600px) {
        padding: 30px;
        margin: 160px 30px 80px 30px;
    }
    
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, 
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        font-family: Roboto-Bold;
        font-size: 15px;
        color: #494949;
        border: none;
        padding: 14px 70px;
        background: url(data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAMMAAAAsCAYAAADYbQz9AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAw6ADAAQAAAABAAAALAAAAADQTWqqAAAEoElEQVR4Ae2dO2gUQRjHZ3dzGnKPQFBJComFqCiWiggiKXxgoYKRFL6CbySQykLFQgULey3sLLQUbOzsNIIRC7UQKy1EG8Xs7SWBcLv+J3qJub29ndnbvWxu/4Hl7mZnvu+bX/Z/89rZM0Sb/srl8lbP83bDnTy2CE+sxWufYRh9bQqBblJMANeGg/B+GsL4JQzxFa+vTGFO9JR6JtoVtpGkIwhgHexf8FzvPC76DUn6ou3OJACRlCGMx4ZlPCgUCh+SrGUiYnAcp9913XuoxKkkg6ftbBHwhPfasqzxfD4/mUTNYxUDVGxV7MqYK9zbaAlKSQRMm5kn4KKL/bBQKlzDNfY7ThqxiWG+Nai6zxDgzjgDpC0SCCDww/KsIz29PW8Czmsnm9olGhTA2GAvxgXvKYQGcJiUFIH+qlF96Uw543E5aLllQIuwH0J4joCsuIKiHRLQJHC3WCre0Czjy96SGKbt6d1VUX0Bq90+y0wggXYSMMRYsVi834rLyGKwbVuuFUyia1RoJQCWJYHYCBjiGATxNKq9SGLArFGXU3bewen2qI5ZjgTiJoDr0ra6rC2Yev0exXakATSEcAvOKIQoxFkmMQLopZTcqvskqgPtlgEzR9vQPfoY1SHLkUDSBLDYO4p1iEe6fvRbBlfc1HXC/CTQTgLoLt3BsUrXp5YYZqdmN+ImquO6TpifBNpKwBDrMeV/UdenlhjmjLmrcKBVRjcg5ieBWAh44rquHeULG81OHscJXQfMTwLLRGAA0/+HdXwriwED5xGM1vM6xpmXBJaZwAUd/8pigFEtwzpBMC8JJEEAs0qHKpXKgKptJTHM2rObYHiXqlHmI4GUEDCxr+acaixKYsDA+YqqQeYjgTQRkLssMdZVWk8LFQMM5WBwNE0VZCwkoEoA49xBdJX2qeQPFQMMDcNgr4ox5iGBNBKQrYNKXKFigCEOnFVIMk+aCRzFNOuasACbimFmZmYQBobCjPA8CaScQA6PnTkTFmNTMVTnqpfDDPA8CawEAnhIRejtGYFiwMDZxKM5lPpaKwEGY8w2ASwNbEJXaU8zCoFiwIqzXMoO7Wc1M85zJJAyAk2/3APFgEpw4Jyy/yTDaY0AWocR9HgCZ0YbikEuYaPgwdZcszQJpI7AatzafTIoqoZiwBK2bBUangsyxHQSWBEEPHEpKE7fMrVcusYe528ooHyDU5BxppNAGgmYlrkDDw14Wx+b79sfXaQDyEQh1JPi544hgIcGNBwP+8SgunTdMWRYkcwRQO/nBA7f3pwlYvi3ZK21OyhzJFnhFU9AblKTm9XqK7JEDDh5FkeuPhM/k0AHEvB1lZYMoO0p+4u85bUDK84qkYCPQE7kNneXuj/XTiy0DGg29lIINSx8zQKB+k1rC2IQLlecs3ABsI6LBOSmNQykF4YF82JAQi8eDja8mI3vSKDzCchNa3LzWq2m82JwbOc0ElbXEvlKAlkh8P/mtb/dJINdpKz881lPH4Ghf5vYhDk9NS1/kJCPl/cxYkJWCNQ2sZn4kTjffGtWILCeJCAJyE1sGDebXbhV+xPe3CIWEsgyAXSVBv4A945C+4LMZLcAAAAASUVORK5CYII=) bottom center no-repeat;
        
        @media (max-width: 750px) {
            padding: 14px 20px;
        }

        @media (max-width: 380px) {
            padding: 14px 0px;
        }
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, 
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        border: none;
        background: url(data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAMMAAAAsCAYAAADYbQz9AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAw6ADAAQAAAABAAAALAAAAADQTWqqAAAEvUlEQVR4Ae2cz49LURTHz3nTmWASEcSvjWBhIYONkEkYCz8SFiIMZlqUYbAgET8ipiNFEP8BicSoKcKCpQULv0IigowVC1sLVkbix+u7zu1MGzN9bd+vVtv3fUkz09d7zz3n035737nnvjJV6UjEbi6kDLUrttqZaJEMO10e04h5apVcwDC1TEDRsCL6RiwPRZ+Z6aUi48WFdOxVtdyWz2XljuTO1AzToh4ZYb885lVuJFhuWAIiEmKVtlTkysVb0XeVjLMCYlCciN5Yq5TRK8Y3idIjlQwAtkNEQKk3ciVxNfJ7wu3kvW3DQUceqBiSyaRhfpx3WBw+J45ODtpZ2AMBpUgxqYFMhE5cSu3+FiSRwMSQ7Lw+y2xuuiMzQUeQDsIWCNgTUF/Y4h3nb+96Yv+6+7OG+y6FPfq7Uh1mi/EWQihkgzOVIsCzLKbHfdHUKdKTRQBHk18bskq0Wmw8kksjXBb5hYn+rgjIipMhKlizqu39lKdDDx666mzT2JcY+qODyxRZD5l5oo1tnAKB6hBgXrFy8eZfz4buP/czoGcx9HcNLLJYPWLiKX4cQF8QCIJAdoZYvPnr06H7r73a85Qz6FUjy+BrIoRpXgdGPxAImoAU7S4ntg8s8GrXkxj+fJp/UoSwwuug6AcClSAgs0Orihgp/WXtxb7rTpIntLGis14GQx8QqDQBEUS7/rL2Mo5rMViUOSZLqC1eBkMfEKgSgSNHO++6XtRxJYZT3en5sqQbq1JAGAYEPBGQ2WH2pJafB9x2diWGJjbPyNqu5xUot86hPQj4IHDS7ezgWAzHd6ZaiXirD+fQFQSqRkDPDq2RX+vdDOhYDC0Z6pQBRBA4QKA+CChWcTeeOhaD1L0PujGMtiDw3wmw2tDXnZ7r1A9HYjjdnV4qBpc7NYp2IFALBKQW1sxk7nHqiyMxGGTGnRpEOxCoJQKKOO50V2tZMYwmztFaChC+gIBTArL6OTfRNegokS4rBp04S5FN37yPAwTqkoAk0o7y3bJikAbxuiQAp0EgR0AS6WTXrbJf6CXFoDNx2Qm4KmcTf0GgHgnoRPqPYZbdOVFSDJKJH5JrLikv4ACB+iYgH+K95RLpomLo7b3aLBXnnvpGAO9BIE+gLREb7Mg/s/mnqBhmfJ+0EYmzDTGcqlsCSlnxUs4XFYPTDLyUcbwGArVFgLeWSqRtxTBawl5XW4HAGxDwR0DvrSuVSNuKQZewkTj7A4/etUlABBEv5pmNGOQCKVvCLtYF50GgrgksGd1rVxBEgRh06VqXsAta4gQINAgBg03binSBGJA4N8g7jjCKEpArn9jInruxTcaIIZtpS+l6bBM8A4HGIqAT6eyeu3FhjRGDZNr7dOl6XBs8BYGGI2B3s9o/YtA/e4+72RruXUdAxQgsH59I58WgS9VInItxw/lGJDD+prW8GMqVqhsRBmIKOwGOjuzBG+GQFcNIiRo/AxP2j0bo4peb1mYOT9ySizsrBl2i1hl27iT+gkBYCMj9OvmaQ1YMpUrUYYGCOMNJQN+8lvs5GaMvOqh/Wn5JOFEg6rAT0Hvw9E1smoPMDJn8NBF2MIg/rAS4RyfSEVLGByLrbFgxIG4Q0ATm/Gid+RdCZu7ukfAppgAAAABJRU5ErkJggg==) bottom center no-repeat;
    }

    .ant-tabs-tab-btn:focus, 
    .ant-tabs-tab-remove:focus, 
    .ant-tabs-tab-btn:active, 
    .ant-tabs-tab-remove:active {
        color: #D4007F;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #FFFFFF;
    }

}

.reporte-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .rc-filtros {
        display: grid;
        grid-template-columns: 130px 1fr 130px;
        grid-template-rows: auto;
        column-gap: 15px;
        row-gap: 10px;

        @media (max-width: 1360px) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            row-gap: 10px;
            justify-items: center;
            align-items: center;
        }
    }

    .rc-corte {
        font-family: Roboto-Italic;
        font-size: 10px;
        font-weight: bold;
        color: #6D6D6D;
        text-align: center;
        margin: 5px 130px 0px 130px;

        @media (max-width: 760px) {
            margin: 5px 0px 0px 0px;
        }
    }

    .rc-regresar {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        cursor: pointer;
        column-gap: 5px;
        width: fit-content;
        background-color: #FFFFFF;
        font-family: 'Roboto-Medium';
        font-size: 14px;
        color: #AAA7AA;
        border: none;

        &:hover {
            color: #D4007F;
            .anticon svg {
                color: #D4007F;
            }
        }
        
        .anticon svg {
            color: #AAA7AA;
        }
    }

    .rc-reporte {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 0px;
        margin: 27px 0px 15px 0px;

        .rc-reporte-title {
            font-family: Roboto-SlabRegular;
            font-size: 26px;
            color: #7C5BA2;

            @media (max-width: 380px) {
                font-size: 15px;
            }
        }

        .rc-reporte-nivel {
            font-family: Roboto-Regular;
            font-size: 14px;
            color: #868686;

            @media (max-width: 380px) {
                font-size: 10px;
            }
        }
    }
    
    .rc-error {
        font-family: Roboto-SlabRegular;
        font-size: 26px;
        color: #7C5BA2;
        margin-top: 27px;
    }

    .rc-sin-datos {
        font-family: Roboto-Regular;
        font-size: 50px;
        color: #F4F3F4;
        text-align: center;
        margin-top: 90px;
    }

}

.reporte-filtros-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    row-gap: 10px;

    .rfc-combo-reportes {
        width: 500px;

        @media (max-width: 830px) {
            width: 250px;
        }

        @media (max-width: 380px) {
            width: 200px;
        }

    }

    .rfc-combo-niveles {
        width: 250px;

        @media (max-width: 380px) {
            width: 200px;
        }
    }

    .ant-select .ant-select-selector {
        min-width: 250px;
        text-align: left;
        border-radius: 4px;
        color: #333333;
        font-family: 'Roboto-Light';
        font-size: 12px;
        opacity: 0.34;

        @media (max-width: 380px) {
            min-width: 200px;
        }
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-selector:hover,
    .ant-select-open .ant-select-selector {
        border-color: #989898 !important;
        box-shadow: none !important;
    }

    .anticon svg {
        color: #D4007F;
    }

}

.reporte-exportar-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 6px;
    justify-content: flex-end;
    align-items: center;

    .rec-option {

        .rec-option-label{
            font-family: Roboto-Bold;
            font-size: 12px;
            color: #AAA7AA;
        }
    
        .anticon svg {
            color: #AAA7AA;
        }
    
        &:hover {
            cursor: pointer;

            .rec-option-label{
                color: #D4007F;
            }
        
            .anticon svg {
                color: #D4007F;
            }
        }
    }

}

.reporte-cotas-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: fixed;
    z-index: 3;
    top: 185px;
    right: 0px;

    .rcc-icon-close {
        width: 60px;
        height: 55px;
        background-color: #D4007F;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 49% 0% 0% 47% / 64% 10% 10% 65%;
        
        .anticon svg {
            width: 31px;
            height: 31px;
            color: #FFFFFF;
        }
    }

    .rcc-icon-open {
        width: 25px;
        height: 55px;
        background-color: #D4007F;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-top-left-radius: 110px;
        border-bottom-left-radius: 110px;

        .anticon svg {
            width: 21px;
            height: 31px;
            color: #F6F6F6;
        }
    }

    .rcc-data {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 10px;
        padding: 13px;
        background-color: #FFFFFF;
        box-shadow: 1px 6px 5px #8D8D8DBF;

        .rcc-data-title {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            column-gap: 10px;
            justify-items: flex-start;
            align-items: center;

            .rcc-data-title-text{
                font-family: RobotoCondensed-Bold;
                font-size: 23px;
                color: #D4007F;
            }

            .anticon svg {
                width: 31px;
                height: 31px;
                color: #D4007F;
            }
            
        }

        .rcc-data-group {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            .rcc-data-group-title {
                font-family: Roboto-Bold;
                font-size: 12px;
                color: #494949;
            }

            .rcc-data-group-content {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                column-gap: 5px;

                font-family: Roboto-Light;
                font-size: 9px;
                color: #000000;
            }
        }

    }

}

.ant-pagination-item {
    border: none;
}

.ant-pagination-item:focus a, 
.ant-pagination-item:hover a{
    color: #D4007F;
}

.ant-pagination-item-active a,
.ant-pagination-item-active:focus a, 
.ant-pagination-item-active:hover a {
    color: #D4007F;
}

.ant-pagination-prev .ant-pagination-item-link, 
.ant-pagination-next .ant-pagination-item-link{
    border: none;
    .anticon svg {
        color: #000000;
    }
}
.ant-pagination-options-quick-jumper input:focus, 
.ant-pagination-options-quick-jumper input:hover,
.ant-pagination-options-quick-jumper input-focused {
    border-color: #D4007F;
}

#publicador-tabla .ant-table-thead tr th {
    font-family: 'Roboto-Bold';
    font-size: 15px;
    text-align: center !important;
    background-color: #FFFFFF;
    border-top: 2px solid #F3F2F3;
    padding-right: 35px;

    @media (max-width: 380px) {
        font-size: 10px;
    }

    .anticon svg {
        width: 12px;
        height: 12px;
        color: #7C5BA2;
    }
}

#publicador-tabla 
.ant-table-thead > tr > th, 
.ant-table-tbody > tr > td, 
.ant-table tfoot > tr > th, 
.ant-table tfoot > tr > td {
    padding: 2px 2px;
}

#publicador-tabla .ant-table-filter-column-title {
    padding: 0px 5px;
}

#publicador-tabla .table-row {
    font-family: 'Roboto-Light' !important;
    font-size: 12px !important;
    color: #000000 !important;

    @media (max-width: 380px) {
        font-size: 9px;
    }
}

#publicador-tabla .table-total {
    font-family: 'Roboto-Light' !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    background-color: #7C5BA2 !important;

    @media (max-width: 380px) {
        font-size: 9px;
    }
}

#publicador-tabla .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: unset;
}

#publicador-tabla-filtro-fecha .ant-picker-focused,
#publicador-tabla-filtro-fecha .ant-picker:hover {
    border-color: #7C5BA2;
}

.ant-picker-today-btn {
    color: #7C5BA2;

    &:hover {
        color: #7C5BA2;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #7C5BA2;
    border: none;
}

.ant-btn-primary,
.ant-btn-primary:hover, 
.ant-btn-primary:focus{
    background: #7C5BA2;
    border-color: #7C5BA2;
}

.ant-picker-suffix,
.ant-picker-clear {
    .anticon svg {
        color: #7C5BA2;
    }
}

.publicador-tabla-filtro-texto {
    padding: 10px;

    .publicador-tabla-filtro-texto-input {
        font-family: Roboto-Light;
        font-size: 12px;
        display: block;
        margin-bottom: 8px;
        
        &:hover,
        &:focus,
        &:active {
            border-color: #7C5BA2;
        }
    }

    .publicador-tabla-filtro-texto-button-inverted {
        width: 90px;
        font-family: Roboto-Light;
        font-size: 12px;
        background-color: #7C5BA2;
        color: #FFFFFF;
    }

    .publicador-tabla-filtro-texto-button {
        font-family: Roboto-Light;
        font-size: 12px;
        width: 90px;

        &:hover,
        &:focus,
        &:active {
            color: #7C5BA2;
            border: none;
        }

    }

}


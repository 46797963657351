.hide{
    display: none;
}
  
.show{
    display: block;
}
  
.disabledSeccion {
      pointer-events: none;
      opacity: 0.4;
}
  
.enableSeccion {
      pointer-events:auto;
}
  
.titulo-exito{
    font-size: 33px;
    font-family: Roboto-Light;
    color:#1D1D1B;
    text-align: center;
}
  
.notificacion-exito{
    font-size: 18px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Regular;
    color:#575756;
    
}
  
#titulo-barra-progreso{
    margin-bottom: 3px;
}
.vacio{
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b3b3b3;
  }
.lleno{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d5007f;
}
  
#sinConvocatoria #wizard_id_convocatoria .ant-select-selection  {
    border-color: #f5222d !important;
}
  
#conConvocatoria #wizard_id_convocatoria .ant-select-selection  {
    border-color: lightgray;
}
  
#campoIncorrecto input{
    border-color: #f5222d !important;
}
  
#campoCorrecto input{
    border-color: lightgray;
}
  
.mostrarErrorFecha{
    color: #f5222d;
    display: block;
    visibility: initial;
    margin-top: -15px;
    margin-left: 3px;
  }
  
.ocultarErrorFecha{
    color: #000;
    display: none,
  }
  
.tablaDispositivos{
      text-align: center;
      top: 10px;
      left: 10px;
      width: 420px;
      height: 50px;
      border: 0.5px solid #e2dfdf;
      opacity: 1;		
  }
  
.tablaCabezal {
    text-align: center;
    top: 331px;
    left: 206px;
    width: 400px;
    height: 50px;
    border: 0.5px solid #e2dfdf;
    opacity: 1;			
}

.tooltip-ubica .ant-tooltip-inner {
    width: 400px;
}
  

#img-exito-modal{
  width: 230px;
  height: 210px;
}

.modal-fechas .ant-modal-content{
  text-align: center;
  width: 400px;
  height: 300px;
}

.titulo-error{
  font-size: 33px;
  font-family: Roboto-Light;
  color:#1D1D1B;
  text-align: center;
}

.notificacion-error{
  font-size: 18px;
  text-align: center;
  justify-content:center;
  font-family: Roboto-Regular;
  color:#575756;
  
}

.tab-registra-Sedes .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color:#D3097F !important; 
}

.tab-registra-Sedes .ant-tabs-ink-bar {
  position: absolute;
  background:#D3097F;
  pointer-events: none;
}


.modalExito .ant-modal-content{
  text-align: center;
  width: 327px;
  height: 470px;
}

.modalModifica .ant-modal-content{
  text-align: center;
  width: 327px;
  height: 470px;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus {
  background: #D3097F;
}

#divide{
 width: 1046% !important;
}          

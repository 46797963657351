.estilo_mensaje {
    min-height: 2.2em;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    width: 50%;
    margin: 10px 0 10px;
    color: white;
    font-family: 'Roboto-Regular';
    padding-right: 0.1em;
    text-align: right;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.estilo_mensaje_warning {
    background-color: rgb(252, 207, 16);
}

.estilo_mensaje_info {
    background-color: rgb(219, 219, 219);
}

.estilo_mensaje_ok {
    background-color: rgb(139, 210, 63);
}

.estilo_mensaje_error {
    background-color: rgb(237, 32, 21);
}

.estilo_mensaje_contenido {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    flex: 1 0;
    padding: 5px 10px 5px 0;
}

.estilo_mensaje_icono {
    display: inline-block;
    font-size: 1.9em;
    height: 1em;
}

.estilo_mensaje_icono>.anticon {
    vertical-align: 0;
}

.styleModulo{
	top: 172px;
	left: 100px;
	width: 1182px;
	height: 530px;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	opacity: 1;
}
.tableStyle {
	text-align: center;
	top: 30px;
	border: 0.5px solid #e2dfdf;
	opacity: 1;
		}

		.tableStyle1 {
			text-align: center;
			top: 331px;
			left: 206px;
			height: 50px;
			border: 0.5px solid #e2dfdf;
			opacity: 1;
				}

.table2Style {
	text-align: center;
	top: 30px;
	left: 100px;
	height: 50px;
	width: 100%;
	border: 0.5px solid #e2dfdf;
	opacity: 1;
		}

		.table2Style1 {
			text-align: center;
			top: 331px;
			left: 206px;
			height: 50px;
			border: 0.5px solid #e2dfdf;
			opacity: 1;
				}


.tituloTable {
	top: 34px;
	left: 0px;
	width: 500px;
	height: 26px;
	color: var(--unnamed-color-494949);
	text-align: left;
	font: normal normal normal 20px/26px Roboto Slab;
	letter-spacing: 0px;
	color: #494949 !important;
	/*top: 220px;
	left: 158px;
	text-align: left;
	letter-spacing: NaNpx;
	opacity: 1;
	color: #080808 !important;*/
}
.iconStyle{
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

.paginationStyle{
	text-align: center;
	text-decoration-line: inherit;
}
.content{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.search{
	position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -6px;
}

.btonSeparacion{
	margin-left: 10px;
}

.btones{
	padding-top: 10px;
}


#guardar-seleccionados{
	float: right;
	margin-bottom: 20px;
	margin-top: 45px;
	background-color: #D3097F;
	border-radius: 10px;
	width: 118px;
	height: 34px;
  }

  #guardar-seleccionados:hover{
	background-color: #F4F3F4;
  }
  #guardar-seleccionados:hover span{
	color: #6D6D6D;
  }
  #guardar-seleccionados span{
	color: #fff;
	font-size: 14px;
	font-family: "Roboto-Medium";
  }

.formatSeacrh{
	margin: 10px;
	width:200px;
	padding:5px;
	font-size: 1em;
	background-color:rgb(245, 248, 245);
	color:#000;
}
		.modalContainerBandeja {
			display: none;
			position: fixed;
			z-index: 1;
			padding-top: 100px;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			overflow: auto;
			background-color: rgb(70, 62, 62);
			background-color: rgba(212, 28, 28, 0.4);
		}

.modalContainerBandeja .modal-content {
	background-color: #030303;
	margin-left: auto;
	margin-right: auto;
	margin-top: 170px;
	margin-bottom: auto;
	padding: 20px;
	border: 1px solid lightgray;
	width: 520px;
	height: 128px;
	text-align: center;
	border-radius: 20px;
}

.closeBandejaModal{
	background: #fff;
	position: absolute;
	right: 560px;
	top: 286px;
}


#printer-icon svg,
#eye-icon svg,
#resultado-casillas ul li svg,
#tabla-volun-seleccionados span svg,


  .tituloCasillas{
	top: 0px;
	left: 0px;
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 26px/26px Roboto Slab;
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-7c5ba2);
	text-align: left;
	font: normal normal normal 26px/26px Roboto Slab;
	letter-spacing: 0px;
	color: #7C5BA2;
  }


  #icon-pregunta span svg{
	color:#7c5ba2;
	width: 20px;
	height: 20px;
  }
  #icon-preguntaTabla{
	color:#7c5ba2;
	width: 20px;
	height: 20px;
  }
  #icon-asignar span svg{
	color:#09080a;
	width: 15px;
	height: 15px;
  }

  /*.ant-modal-body {
		padding: 0px !important;
		height: 470px !important;
		background-image:none !important;
}*/
.modalCasillas .ant-modal-content .ant-modal-body {
	padding: 0px;
	height: 407px;
}

#asg_img img{
	width: 75%;
	height: 95%;
	margin-left: 50px;
  }
.div_modal{
		display: grid;
}

.texto_modal{
	text-align: center;
	letter-spacing: 0px;
	color: #1D1D1B;
	opacity: 1;
	font-family: Roboto-Light;
	font-size: 33px;
	margin: -8px;
  }
  
.texto_modal2{
	text-align: center;
	letter-spacing: 0px;
	color: #1D1D1B;
	opacity: 1;
	font-family: Roboto-Light;
	font-size: 33px;
	margin: -11px;
}

  .texto_modal3{
	text-align: center;
	letter-spacing: 0px;
	color: #575756;
	opacity: 1;
	font-family: Roboto-Regular;
	font-size: 18px;
  }

  #guardar-seleccionados{
	float: right;
	margin-bottom: 20px;
	margin-top: 45px;
	background-color: #D3097F;
	border-radius: 10px;
	width: 118px;
	height: 34px;
  }

  #btn_aceptar{
	float: right;
	margin-bottom: 10px;
	margin-top: 5px;
	background-color: #D3097F;
	border-radius: 10px;
	width: 118px;
	height: 34px;
  }

  #btn_aceptar:hover{
	background-color: #F4F3F4;
  }
  #btn_aceptar:hover span{
	color: #6D6D6D;
  }
  #btn_aceptar span{
	color: #fff;
	font-size: 14px;
	font-family: "Roboto-Medium";
  }

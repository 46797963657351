.inicia-sesion-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    height: 50px;
    z-index: 1000;
    margin-top: 98px;
    background-color: #FFFFFF;
    box-shadow: 8px 9px 6px #DAE1E0;
    padding: 5px 50px;
  
    .isc-image {
        width: 25px;
	    height: auto;
    }

    .isc-title {
        color: var(--rosa-ine);
        font-family: Roboto-Regular;
        font-size: 18px;
    }

    @media (max-width: 500px) {
        padding: 5px 20px;
    }
    
  }
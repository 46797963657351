.hideAdvRegistro{
  display: none;
}
.showAdvRegistro{
  display: flex;
}
#titulo-componente{
  color: #7C5BA2;
  font-size: 26px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: 'RobotoSlab-Regular';
}

#nota-requeridos{
  font-size: 14px;
  font-family: 'Roboto-Regular';
  color : #333333;
}
#div-texto-obligatorio{
  /*top: -29px;*/
  position: relative;
}
#obligatorio{
  color:#F8455A;
}
.modalVoluntario .ant-modal-content {
    border: 5px solid #D3097F;
}
/* tabla consulta */
#card-tabla-voluntarios .ant-card-head-title{
  color:#333333;
  font-size: 15px;
  font-family: "Roboto-Bold";
  text-align: center;
}
#editar-voluntario {
  margin-left: 15px;
  margin-right: 15px;
}
#ver-voluntario span svg{
  color :#333333;
  width: 17px;
  height: 15px;
}
#editar-voluntario span svg{
  color :#333333;
  width: 12px;
  height: 13px;
}
#enviar-correo span svg {
  color :#333333;
  width: 15px;
  height: 11px;
}
#tabla-consulta-voluntarios .ant-table-tbody > tr.ant-table-row:hover > td {
  color: #333333;
  font-family: 'Roboto-Light';
  font-size: 11px;
}
#tabla-consulta-voluntarios .ant-table-thead > tr > th,
.ant-table-tbody > tr > td, .ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px;
}
#tabla-consulta-voluntarios .ant-table-tbody tr,
#tabla-consulta-voluntarios .ant-table-cell{
  text-align: center;
}
/* formularios*/
#span-ayuda-claveE{
  position: absolute;
  right: -5px;
}
#titulo-folio{
  font-family: 'RobotoSlab-Regular';
  font-weight: bold;
  color: #333333;
  font-size: 20px;
  margin-top: -20px;
}
#ayuda-tipoPersona{
  position: absolute;
  right: 7px;
}
#img-ayuda{
  width: 239px;
  height: 158px;
  display: none;
}
#tooltip-genero{
  background-color: #F9B2B2;
  color:#333333;
}
#formulario-registro{
  padding-left: 30px;
  padding-right: 30px;
}
#formulario-registro .pestanaRegistro .ant-tabs-content-holder{
  margin-top: 30px;
}
.pestanaRegistro .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#7C5BA2;
  border-bottom: 5px solid;
}
.botonIzqRegistro{
  width: 47px;
  height: 46px;
  float: left;
  background-color: #D3097F !important;
  border-color: #D3097F;
}
.botonDerRegistro{
  width: 47px;
  height: 46px;
  float: right;
  background-color: #D3097F !important;
  border-color: #D3097F;
}
.botonDerRegistro
.ant-btn-primary  {
    background-color: #D3097F;
    border-color: #D3097F;
}
#genero{
  background-color: #F9B2B2;
  color:#000000;
}
.formularioInput .ant-form-item-label label,
#campo-genero span,
#hablaIndigena span{
  font-weight: normal;
  font-family: 'Roboto-Regular';
}
#texto-check{
  font-family: 'Roboto-Regular';
  font-size: 12px;
  color: #333333;
  text-align: center;
}

/* modal aviso privacidad */
.modalAvisoPrivacidad{
  font-family: 'Roboto-Regular';

}
.modalAvisoPrivacidad .titulo{
  color: #7C5BA2;
  font-size: 24px;
  text-align: center;
}
.modalAvisoPrivacidad .subtitulo{
  text-align: center;
  font-size: 18px;
}
.modalAvisoPrivacidad .pregunta{
  font-weight: bold;
  font-size: 16px;
}
.modalAvisoPrivacidad .respuestaPregunta{
  text-align: justify;
  font-size: 14px;
}

/* tabla */
#ver-voluntario span svg:hover,
#editar-voluntario span svg:hover{
  color:#D3097F;
}



.estiloCuadrado .ant-radio-inner{
	border-radius: 0%;
}

.estiloCuadrado .ant-radio-inner::after {
	border-radius: 0px;
}

.espacios{
	margin-bottom: 15px;
}

#ultimo-corte{
	font-size: 9px;
}

.rText-navigation-style{
	color:#272727 !important;
	font-size: 15px;
	font-family: Roboto-Bold;
}

.rText-navigation-style .ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #272727;
}


.sitio-layout-background {
	background: transparent;
	padding: 0px !important;


}

.titulo-resultados{
	color: #717577;
	font-size: 20px;
	font-family: Roboto-Bold;
}

.resultados-card-list {
    display: grid;
    width: 100%;
    height: calc(100vh + 150px);
    margin-top: 0px;
    padding: 1px 1px 1px 1px;
    grid-template-columns: 567px 480px;
    grid-auto-rows: 110px;
    column-gap: 5px;
    row-gap: 2px;
    grid-template-areas:
        "preguntas      estados"
        "graficas       estados"
		"graficas       estados"
		"graficas       estados"
		"graficas       participantesxSexo"
		"graficas       participantesxSexo"
		"graficas       participantesxSexo"
		"graficas       participantesxSexo";
    justify-items: stretch;
    align-items: stretch;
    align-self: center;
    line-height: 1.15;
	background-color: transparent;
}


.rPreguntas-div-style{
	padding: 0px;
	margin-top: -20px !important;
}

.rFont-pregunta{
	font-size: 13.02px;
	font-family: Roboto-Regular;
	color: #333333;
	font-weight: bold;
}

.rSelect-pregunta{
	width: 100%;
	height: 32px;
}

.rDiv-style{
	margin-bottom: 5px;
}

.rTexto-descargar{
	font-size: 10px;
	font-family: Roboto-Bold;
	color: #AAA7AA;
}
.show{
	display: block;
}
.hide{
	display: none;
}
.segundoUl{
	list-style: none;
}
.listaCota{
	text-decoration: none;
	list-style: none;
	float: left;
	padding-left: 0px;
	text-align: left;
	margin-left: 15px;
}
.rDiv-centrar-grafica{
	display: block;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	justify-content: center;
}

.rDiv-centrar-estado{
	display: block;
	margin-right: auto;
	margin-left: auto;
	margin-top: 79px;
	margin-bottom: 10px;
	text-align: center;
	justify-content: center;
}


.rDiv-centrar-participante{
	display: block;
	margin-right: auto;
	margin-left: auto;
	margin-top: 100px;
	margin-bottom: 10px;
	text-align: center;
	justify-content: center;
}



.rGrafica-null{
	width: 185px;
	height: 185px;
}

.rEstado-null{
	width: 98px;
	height: 86px;
}

.rParticipante-null{
	width: 118px;
	height: 119px;
}

.rTexto-null{
	font-size: 26px;
	font-family: Roboto-Regular;
	color:#CBC9C9;

}


.rSeleccion-style{
	color:#D3097F;
	font-size: 20px;
	font-family: Roboto-Regular;
	margin-bottom: 5px !important;
	margin-top: 5px !important;
}

.rSeleccion-style.ant-breadcrumb > span:last-child {
	color: #D3097F;
}

.rSeleccion-style .ant-breadcrumb-separator {
	margin: 0 8px;
	color: #D3097F !important;
}

.scroll{
	height: 450px;
	overflow-y: scroll;
}

.rTitle-card{
	font-size: 18px;
	font-family: Roboto-Bold;
	color:#333333;
}

.rText-estado{
	font-size: 26px;
	font-family: Roboto-Bold;
	color:#5E6264;
}

.rDiv-nombreEstado{
	justify-content: center;
	text-align: center;
}

.rNumero-style{
	font-size: 50px;
	font-family: Roboto-Bold;
	color:#D3097F;
}

.styleDescMujer{
	color: var(--unnamed-color-74418a);
	text-align: center;
	letter-spacing: 0px;
	color: #74418A;
	opacity: 1;
	font-size: 19px;
	font-family: Roboto-Bold;
	/*margin-right: 30px;*/
}

.styleDescHombre{
	color: var(--unnamed-color-74418a);
	text-align: right;
	letter-spacing: 0px;
	color: #494848;
	opacity: 1;
	font-size: 19px;
	font-family: Roboto-Bold;
	/*margin-right: 30px;*/
}

.styleDescNinguno{
	color: var(--unnamed-color-74418a);
	text-align: center;
	letter-spacing: 0px;
	color: #FFAE00;
	opacity: 1;
	font-size: 19px;
	font-family: Roboto-Bold;
	/*width: 135px;*/
}

.styleDescMujerPorcentaje{
	color: var(--unnamed-color-74418a);
	text-align: center;
	letter-spacing: 0px;
	color: #74418A;
	opacity: 1;
	font-size: 25px;
	font-family: Roboto-Bold;
	/*margin-right: 30px;*/
}

.styleDescHombrePorcentaje{
	color: var(--unnamed-color-74418a);
	text-align: right;
	letter-spacing: 0px;
	color: #494848;
	opacity: 1;
	font-size: 25px;
	font-family: Roboto-Bold;
	/*margin-right: 30px;*/
}

.styleDescNingunoPorcentaje{
	color: var(--unnamed-color-74418a);
	text-align: center;
	letter-spacing: 0px;
	color: #FFAE00;
	opacity: 1;
	font-size: 25px;
	font-family: Roboto-Bold;
	/*margin-right: 30px;*/
}

.site-layout-background{
	background-color: #fff;
}

.radioEstilos{
	font-size: 12px;
	font-family: Roboto-Regular
}

.tituloCard{
	color: var(--unnamed-color-333333);
	color: #333333;
	opacity: 1;
	font-size: 18px;
	font-family: Roboto-Bold;
	margin-top: 0px;
}

#boton-export-pdf:hover,
#boton-export-img:hover{
	cursor:pointer;

}
#boton-export-pdf,
#boton-export-img{
	border: none;
	float: right;
	background-color: transparent;
}
.rImg-style{
	height: 70px;
	width: 70px;
	justify-content: center;
}

.rImg-style-dos{
	height: 70px;
	width: 70px;
	justify-content: center;
}


.rDiv-participantes{
	margin-top: 50px;
	justify-content: center;
	text-align: center;
}

.rDiv-participantes-label{
	margin-top: 10px;
	justify-content: center;
	text-align: center;
}

.rText-styleSinResponder{
	font-size: 11px;
	font-family: Roboto-Regular;
	color:#333333;
}

.rText-Slide-inline{
	font-family: Roboto-Regular;
	font-size: 12px;
	margin-left: 10px;

}

.ant-menu-submenu-popup > .ant-menu {
	background-color: #fff;
	box-shadow: none !important;
}

.rMenu-principal-style{
	width: 220px;
	background-color: transparent;
	border-color: transparent;
	margin-bottom: -25px !important;
	font-size: 12px !important;
}

.rText-check{
	font-size: 12px !important;
	font-family: Roboto-Regular;
}

.rCheck-style{
	margin-left: 5px;
	margin-bottom: 5px;
	font-size: 12px !important;
}

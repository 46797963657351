.hide{
    display: none;
}
  
.show{
    display: block;
}
  
.disabledSeccion {
      pointer-events: none;
      opacity: 0.4;
}
  
.enableSeccion {
      pointer-events:auto;
}
  
.titulo-exito{
    font-size: 33px;
    font-family: Roboto-Light;
    color:#1D1D1B;
    text-align: center;
}
  
.notificacion-exito{
    font-size: 18px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Regular;
    color:#575756;
    
}
  
#titulo-barra-progreso{
    margin-bottom: 3px;
}
.vacio{
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b3b3b3;
  }
.lleno{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d5007f;
}
  
#sinConvocatoria #wizard_id_convocatoria .ant-select-selection  {
    border-color: #f5222d !important;
}
  
#conConvocatoria #wizard_id_convocatoria .ant-select-selection  {
    border-color: lightgray;
}
  
#campoIncorrecto input{
    border-color: #f5222d !important;
}
  
#campoCorrecto input{
    border-color: lightgray;
}
  
.mostrarErrorFecha{
    color: #f5222d;
    display: block;
    visibility: initial;
    margin-top: -15px;
    margin-left: 3px;
  }
  
.ocultarErrorFecha{
    color: #000;
    display: none,
  }
  
.tablaDispositivos{
      text-align: center;
      top: 10px;
      left: 10px;
      width: 420px;
      height: 50px;
      border: 0.5px solid #e2dfdf;
      opacity: 1;		
  }
  
.tablaCabezal {
    text-align: center;
    top: 331px;
    left: 206px;
    width: 400px;
    height: 50px;
    border: 0.5px solid #e2dfdf;
    opacity: 1;			
}

.tooltip-ubicaRegistra .ant-tooltip-inner {
    width: 400px;
}
  

#img-exito-modal{
  width: 230px;
  height: 210px;
}

.modal-fechas .ant-modal-content{
  text-align: center;
  width: 400px;
  height: 300px;
}

.titulo-error{
  font-size: 33px;
  font-family: Roboto-Light;
  color:#1D1D1B;
  text-align: center;
}

.notificacion-error{
  font-size: 18px;
  text-align: center;
  justify-content:center;
  font-family: Roboto-Regular;
  color:#575756;
  
}

.tab-registraCasilla .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color:#673C99 !important; 
  font-size: 16px;
  border-bottom: 5px solid;
}

.tab-registraCasilla .ant-tabs-ink-bar {
  position: absolute;
  background:#673C99;
  pointer-events: none;
}


.modalExito .ant-modal-content{
  text-align: center;
  width: 327px;
  height: 470px;
}

.modalModifica .ant-modal-content{
  text-align: center;
  width: 327px;
  height: 470px;
}

#flechaDer .ant-btn .ant-btn-primary{
  width: auto;
  height: auto;
}


#flechaIzq .ant-btn{
  width: auto;
  height: auto;
}

#img-elimina-modal{
  width: 260px;
  height: 240px;
}

.modalElimina .ant-modal-content{
  text-align: center;
  width: 327px;
  height: 500px;
}

.notificacion-elimina{
  font-size: 23px;
  text-align: center;
  justify-content:center;
  font-family: Roboto-Light;
  color:#575756;
  
}

#img-popFechas{
  width: 35px;
  height: 28px;
}


.cuerpo-content{
  font-size: 16px;
  text-align: center;
  justify-content:center;
  font-family: Roboto-Light;
  color:#000000;
  
}

.confirm{
  font-size: 16px;
  text-align: center;
  justify-content:center;
  font-family: Roboto-Light;
  color:#D3097F;
  
}

.div-boton-registra1  button.ant-btn{
  color:#FFFFFF;
  background-color: #D3097F;
  
}

.div-boton-registra1 button.ant-btn:hover{
  color:#FFFFFF;
  background-color: #D3097F;
}

.div-boton-registra1 button.ant-btn:disabled{
  background-color:#F4F3F4;
  color:#6D6D6D;
}

.div-boton-registra2  button.ant-btn{
  color:#FFFFFF;
  background-color: #D3097F;
  
}

.div-boton-registra2 button.ant-btn:hover{
  color:#FFFFFF;
  background-color: #D3097F;
}

.div-boton-registra2 button.ant-btn:disabled{
  background-color:#F4F3F4;
  color:#6D6D6D;
}

.div-boton-registra3  button.ant-btn{
  color:#FFFFFF;
  background-color: #D3097F;
  
}

.div-boton-registra3 button.ant-btn:hover{
  color:#FFFFFF;
  background-color: #D3097F;
}

.div-boton-registra3 button.ant-btn:disabled{
  background-color:#F4F3F4;
  color:#6D6D6D;
}

.boton-anadir  button.ant-btn{
  color:#FFFFFF;
  background-color: #D3097F;
  
}

.boton-anadir button.ant-btn:hover{
  color:#FFFFFF;
  background-color: #D3097F;
}

.boton-anadir button.ant-btn:disabled{
  background-color:#F4F3F4;
  color:#6D6D6D;
}

.div-boton-itineranteFisica  button.ant-btn{
  color:#FFFFFF;
  background-color: #D3097F;
  
}

.div-boton-itineranteFisica button.ant-btn:hover{
  color:#FFFFFF;
  background-color: #D3097F;
}

.div-boton-itineranteFisica button.ant-btn:disabled{
  background-color:#F4F3F4;
  color:#6D6D6D;
}

.div-boton-itineranteVirtual  button.ant-btn{
  color:#FFFFFF;
  background-color: #D3097F;
  
}

.div-boton-itineranteVirtual button.ant-btn:hover{
  color:#FFFFFF;
  background-color: #D3097F;
}

.div-boton-itineranteVirtual button.ant-btn:disabled{
  background-color:#F4F3F4;
  color:#6D6D6D;
}

#minusRegistra svg{
  color:#D3097F;

}




.modulos-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: 30px;
    height: calc(100vh - 250px);
    margin: 175px 80px 20px 80px;
    padding: 10px 30px;
    background-color: #FFFFFF;
    justify-content: center;

    @media (max-width: 700px) {
        height: fit-content !important;
        margin: 175px 40px 20px 40px;
        padding: 10px 20px;
        margin-bottom: 70px;
    }

    @media (max-height: 700px) {
        height: fit-content !important;
    }

    .mdc-title {
        font-family: Roboto-Regular;
        font-size: 40px;
        color: #663C99;
        text-align: center;
    }

    .mdc-modulos {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        height: fit-content;
        min-height: 300px;
        column-gap: 100px;
        row-gap: 10px;
        justify-content: center;
        align-items: center;
        margin: 10px 0px;

        @media (max-width: 1340px) {
            column-gap: 50px;
        }

        @media (max-width: 1200px) {
            column-gap: 10px;
        }

        .mdc-modulo-link {
            display: flex;
            color: #000000;

            .mdc-modulo-data {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                justify-content: space-between;
                text-align: center;
                border-radius: 20px;
                border: solid;
                border-color: #A9A69E;
                padding: 25px;
                width: 200px;
                height: 210px;

                @media (max-width: 800px) {
                    width: 180px;
                    height: 215px;
                }

                @media (max-width: 500px) {
                    width: 140px;
                    height: 200px;
                }

                .mdc-modulo-data-title {
                    margin-top: -27px;
                    font-size: 17px;
                    font-family: Roboto-Regular;
                    color: #333333;

                    @media (max-width: 500px) {
                        font-size: 10px;
                    }

                }

                &:hover{
                    background-color: #C3ADD2;
                    color: #FFFFFF;
                    cursor: pointer;

                    .mdc-modulo-data-title{
                        color: #FFFFFF;
                    }
                }
                
            }
        }

        .mdc-modulo-link-disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.6;
        }
    }

}
.show{
  display: block;
}
.hide{
  display: none;
}

.divSubida{
  width: 240px;
  height: 236px;
  border: 1px solid #E5E5E5;
}
.obligatorio{
  color:red;
}
.etiquetaCampo{
  font-family: 'Roboto-Regular';
  font-size: 14px;
}
.titulo{
  margin: 10px;
}
.cuerpo{
  text-align: center;
}
.areaCarga img{
  width: 200px;
  height: 145px;
  margin-top: 40px;
}
.indicaciones{
  position: absolute;
  display: grid;
  top: 130px;
  left: 45px;
}
.parrafoUno{
  color:#868686;
  font-size: 10px;
  font-family: Roboto-Regular;
}
.parrafoDos{
  color:#D0187F;
  font-size: 10px;
  font-family: Roboto-Regular;
}
.opcionesDoc{
  text-align: center;
  margin-top: 20px;
}
#mostrar-doc{
  width: 18px;
  height: 10px;
  margin-right: 7px;
}
#mostrar-doc:hover,
#eliminar-doc:hover{
  color:#D3097F
}
#eliminar-doc{
  width: 12px;
  height: 13px;
  margin-left: 7px;
}
#mostrar-doc:hover,
#eliminar-doc:hover{
  cursor: pointer;
}
.pie{
  float: right;
  margin-top: 30px;
  margin-right: 7px;
}
.pieOp2{
  float: right;
  margin-top: 10px;
  margin-right: 7px;
}
.pie img,
.pieOp2 img {
  width: 17px;
  height: 17px;
}
#btn-cargar{
  text-align: center !important;
  margin-top: 37px;
}
#btn-cargar .botonActivado{
  margin-bottom: 20px;
  margin-top: 45px;
  background-color: #D3097F;
  border-radius: 10px;
  width: 118px;
  height: 34px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.botonActivado:hover{
  background-color: #D3097F;
  color: #fff;
  border-color: #D3097F;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.botonActivado:focus{
  background-color: #D3097F;
  color: #fff;
  border-color: #D3097F;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.botonDesactivado{
  margin-bottom: 20px;
  margin-top: 45px;
  background-color: #F4F3F4;
  border-radius: 10px;
  width: 118px;
  height: 34px;
  color: #6D6D6D;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.botonDesactivado:hover{
  background-color: #F4F3F4;
  color: #6D6D6D;
  border-color: #F4F3F4;
  color: #6D6D6D;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.botonDesactivado:focus{
  background-color: #F4F3F4;
  color: #6D6D6D;
  border-color: #F4F3F4;
  color: #6D6D6D;
  font-size: 14px;
  font-family: "Roboto-Medium";
}

#errores{
  text-align: center;
}
#msj-error{
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #ff4d4f;
}

/****  media queries ****/
@media screen and (max-width: 1251px) {
  .divSubida {
      width: 200px;
      height: 236px;
  }
  .areaCarga img {
    width: 150px;
    height: 120px;
    margin-top: 15px;
  }
  .etiquetaCampo {
    font-family: 'Roboto-Regular';
    font-size: 12px;
  }
  .indicaciones {
    position: absolute;
    display: grid;
    top: 114px;
    left: 26px;
  }
}

@media screen and (max-width: 992px) {
  .divSubida{
    width: 240px;
    height: 236px;
    border: 1px solid #E5E5E5;
  }
  .areaCarga img{
    width: 200px;
    height: 145px;
    margin-top: 40px;
  }
  .indicaciones{
    position: absolute;
    display: grid;
    top: 130px;
    left: 45px;
  }
  .etiquetaCampo{
    font-family: 'Roboto-Regular';
    font-size: 14px;
  }
}

.divSubidaConsulta{
  width: 230px;
  height: 180px;
  border: 1px solid #E5E5E5;
}
.etiquetaCampoConsulta{
  font-family: 'Roboto-Regular';
  font-size: 14px;
}
.tituloConsulta{
  margin: 10px;
}
.cuerpoConsulta{
  text-align: center;
}

/****  media queries ****/
@media screen and (max-width: 1251px) {
  .divSubidaConsulta {
      width: 200px;
      height: 236px;
  }
  .etiquetaCampoConsulta {
    font-family: 'Roboto-Regular';
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  .divSubidaConsulta{
    width: 240px;
    height: 236px;
    border: 1px solid #E5E5E5;
  }
  .etiquetaCampoConsulta{
    font-family: 'Roboto-Regular';
    font-size: 14px;
  }
}

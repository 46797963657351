.graficaDiv {
    min-height: 530px;
  }

@media screen and (max-width: 1456px) {
.graficaDiv {
    min-height: 500px;
  }
}

@media screen and (max-width: 1332px) {
  .styleDescMujer{
  	font-size: 18px;
  }
  .styleDescHombre{
  	font-size: 18px;
  }
  .styleDescNinguno{
  	font-size: 18px;
  }
  .styleDescMujerPorcentaje{
  	font-size: 25px;
  }
  .styleDescHombrePorcentaje{
  	font-size: 25px;
  }
  .styleDescNingunoPorcentaje{
  	font-size: 25px;
  }
}

@media screen and (max-width: 1282px) {
  .styleDescMujer{
  	font-size: 17px;
  }
  .styleDescHombre{
  	font-size: 17px;
  }
  .styleDescNinguno{
  	font-size: 17px;
  }
  .styleDescMujerPorcentaje{
  	font-size: 50px;
  }
  .styleDescHombrePorcentaje{
  	font-size: 50px;
  }
  .styleDescNingunoPorcentaje{
  	font-size: 50px;
  }
}

@media screen and (max-width: 992px) {
  .styleDescMujer{
  	font-size: 18px;
  }
  .styleDescHombre{
  	font-size: 18px;
  }
  .styleDescNinguno{
  	font-size: 18px;
  }
  .styleDescMujerPorcentaje{
  	font-size: 58px;
  }
  .styleDescHombrePorcentaje{
  	font-size: 58px;
  }
  .styleDescNingunoPorcentaje{
  	font-size: 58px;
  }
}

#options-menu{
  display: flex;
  height:70px;
}

.element_option_menu {
  margin-left: 32px;
  margin-right: 9px;
  margin-top: 8px;
  margin-bottom: 7px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #b3b3b3;
  box-shadow: 10px 5px 5px #b3b3b3;
  width: 340px;
  height: 40px;
}

.element_option_menu img{
  height: 40px;
  width: 40px;
  margin-right: 20px;
  margin-top: 0px;
  float: left;
  margin-left: 47px;
}
.label_lement_menu{
  float: left;
  color: #b3b3b3;
  width: 190px;
  height: 52px;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
#validar{
  margin-left: 182px;
}



#icon-copy{
  margin-right: 10px;
  margin-left: 10px;
}
.ocultarGrafica{
  display: none;
}

/* */


@media screenand and (min-width: 1941px) and (max-height: 848px) {
  #validar {
    margin-left: 330px;
  }
  .element_option_menu img {
    margin-top: 10px;
  }
  #options-menu {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1460px) and (min-height: 848px){
  .element_option_menu {
    height: 65px;
  }
  #options-menu {
    margin-top: 25px;
  }
}

@media screen and (max-width: 1456px) and (min-height:667px){
  .element_option_menu {
    margin-top: 40px;
  }
}


@media screen and (max-width:1300px) and (min-height:800px) {
  .grafica_circulo {
      text-align: center;
      margin-bottom: 64px;
      margin-top: 33px;
  }
  .element_option_menu {
    margin-top: 33px;
  }
  .text_info_circ_lin {
    left: 42%;
  }
}


@media screen and (max-height: 636px) {
  
  #filtros{
    top:110px;
  }
}

@media screen and (max-height: 636px) {
  #validar{
    margin-left: 150px;
  }
}
@media screen and (max-height: 540px) {
  #options-menu {
      margin-top: 12px;
  }
}
@media screen and (max-height: 508px) {
  .layout.template>.ant-layout-content {
    margin: 16vh 0vw 0 !important;
  }
  #filtros{
    top:110px;
  }
}
@media screen and (max-height: 424px) {
  #options-menu {
    margin-top: 15px;
  }
}
@media screen and (max-height: 424px) {
  #options-menu {
    margin-top: 22px;
  }
}

@media screen and (min-width: 1800px){
  #validar {
    margin-left: 289px;
  }

  .element_option_menu {
    font-size: 18px;
    width: 380px;
    height: 52px;
    margin-right: 5px;
  }

}

@media screen and (min-width: 1900px){
.element_option_menu {
  font-size: 18px;
  width: 395px;
  height: 52px;
  margin-right: 30px;
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px){
  .circ-linea .ant-progress-inner {
    width: 295px !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 1477px){
  .element_option_menu{
    margin-right:  -10px;
  }
}

@media screen and (max-width: 1440px){
  .element_option_menu{
    margin-left: 22px;
  }
}



@media screen and (max-width: 1360px){
  .element_option_menu{
    width: 335px;
  }
}

@media screen and (min-width: 1343px) and (max-height: 657px){
  .element_option_menu{
    margin-top: 41px;
  }
}

@media screen and (max-width: 1340px) {
  #validar {
    margin-left: 150px;
  }
}
@media screen and (max-width:1312px) {
  #validar {
    margin-left: 163px;
  }
  .element_option_menu {
    width: 326px;
  }
}

@media screen and (min-width:1280){
  .element_option_menu {
    width: 317px;
  }
}

@media screen and (max-width: 1279px) {
  .element_option_menu {
    width: 313px;
  }
}

@media screen and (max-width: 1247px) {
  #validar {
    margin-left: 140px;
  }
}

@media screen and (max-width: 1230px) {
  .element_option_menu {
      width: 306px;
  }
}

@media screen and (max-width: 1218px) {
  #validar {
    margin-left: 120px;
  }
}

@media screen and (max-width: 1180px) {
  .element_option_menu {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1162px) {
  .element_option_menu {
      width: 286px;
  }
  .element_option_menu img {
    margin-right: 10px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 1136px) {
  .element_option_menu {
    width: 275px;
  }
}

@media screen and (max-width: 1100px) {
  .element_option_menu img {
    margin-right: 0px;
  }
  .element_option_menu {
    margin-left: 5px;
    margin-right: 5px;
    width: 265px;
  }
}

@media screen and (max-width:1040px) {
  .element_option_menu {
    margin-left: 20px;
    margin-right: 20px;
    width: 280px;
  }
  .element_option_menu img {
    margin-left: 20px;
  }
  .label_lement_menu {
    margin-top: 19px;
    font-size: 15px;
  }
  #validar {
    margin-left: 51px;
  }
}

@media screen and (max-width:1032px) {
  #validar {
      margin-left: 50px;
  }
}

@media screen and (max-width:1028px) {
  .element_option_menu {
    margin-left: 10px;
    margin-right: 0px;
    width: 253px;
  }
  .label_lement_menu {
    margin-top: 11px;
    font-size: 15px;
  }
}

@media screen and (max-width:1034px) {
  #validar {
      margin-left: 123px;
  }
}

@media screen and (max-width:1022px) {
  #validar {
      margin-left: 120px;
  }
}


@media screen and (min-width: 1366px)  and (min-height:768px){
  #validar {
      margin-left: 156px;
  }
  .element_option_menu {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1440px)  and (min-height:900px){
  #validar {
      margin-left: 156px;
  }
  .element_option_menu {
    margin-top: 4px;
    height: 41px;
    margin-right: 20px;
  }
  #options-menu {
    margin-top: 34px;
  }
}

@media screen and (max-width:987px) {
  #validar {
      margin-left: 110px;
  }
}

@media screen and (max-width:984px) {
  #validar {
    margin-left: 100px;
  }
  .element_option_menu {
    width: 253px;
  }
}

@media screen and (max-width:930px) {
  .element_option_menu {
    width: 235px;
  }
  .element_option_menu img {
    height: 25px;
    width: 25px;
    margin-top: 5px;
  }
  .label_lement_menu {
    margin-top: 10px;
    font-size: 14px;
  }
  #validar {
    margin-left: 98px;
  }
  #registro-label{
    margin-top: -20px;
    margin-left: 26px;
  }
  #validar-label{
    margin-top: -20px;
    margin-left: 36px;
  }
}

@media screen and (max-width:880px) {
  #validar {
    margin-left: 69px;
  }
}

@media screen and (max-width:886px) {
  .element_option_menu img {
    margin-left: 10px;
  }
  .element_option_menu {
    margin-left: 10px;
    margin-right: 10px;
  }
  #validar {
    margin-left: 74px;
  }
}

@media screen and (max-width:860px) {
  #validar {
      margin-left: 51px;
  }
}
@media screen and (max-width:856px) {
  .element_option_menu img {
    height: 22px;
    width: 22px;
    margin-top: 7px;
  }
  .label_lement_menu {
    margin-top: 12px;
    font-size: 13px;
  }
}

@media screen and (max-width:846px) {
  .label_lement_menu {
    float: right;
    position: absolute;
    margin-left: -90px;
  }
  #reporte-label{
    margin-left: -125px !important;
  }
  .element_option_menu {
    width: 215px;
  }
  #validar {
    margin-left: 60px;
  }
  #registro-label{
    margin-top: 12px;
    margin-left: -100px;
  }
  #validar-label{
    margin-top: 12px;
    margin-left: -100px;
  }
}

@media screen and (max-width:794px) {
  #validar {
    margin-left: 45px;
  }
}

@media screen and (max-width:750px) {
  .label_lement_menu {
    width: 110px;
  }
  #reporte-label{
    margin-left: -75px !important;
  }
  .label_lement_menu {
    position: absolute;
    margin-left: -75px;
  }
  .label_lement_menu {
    margin-top: 4px;
  }
  #reporte-label {
    margin-top: 10px;
  }
  .element_option_menu {
    width: 180px;
  }
  #validar {
    margin-left: 78px;
  }

  #registro-label{
    margin-top: 5px;
    margin-left: -75px;
  }
  #validar-label{
    margin-top: 5px;
    margin-left: -68px;
  }

}

@media screen and (max-width:700px) {
  #validar {
    margin-left: 58px;
  }
}

@media screen and (max-width:686px) {
  #validar {
    margin-left: 50px;
  }
}

@media screen and (max-width:660px) {
  .label_lement_menu {
    margin-top: 7px;
    font-size: 11px;
  }
  #reporte-label {
    margin-top: 12px;
  }
  #validar {
    margin-left: 35px;
  }
}

@media screen and (max-width:640px)  {
  #validar {
      margin-left: 25px;
  }
}

@media screen and (max-width:630px)  {
  .element_option_menu {
    width: 150px;
  }
  #validar {
    margin-left: 70px;
  }
}
@media screen and (max-width:590px) {
  .label_lement_menu {
    margin-left: -60px;
  }
  #validar {
    margin-left: 55px;
  }
}

@media screen and (max-width:570px) {
  #validar {
      margin-left: 39px;
  }
}

@media screen and (max-width:560px) {
  #validar {
      margin-left: 38px;
  }
}

@media screen and (max-width:558px)  {
  .element_option_menu {
    width: 120px;
  }
  #validar {
    margin-left: 90px;
  }
}

@media screen and (max-width:538px){
  #validar {
    margin-left: 70px;
  }
}
@media screen and (max-width:529px)  {

  #validar {
    margin-left: 60px;
  }
}

@media screen and (max-width:499px)  {
  #validar {
      margin-left: 49px;
  }
}

.label_calendar {
	position: relative;
	top: 0;
	left: 0;
	height: 1.5em;
	width: 100%;
	display: block;
	text-align: left;
}

.ant-calendar-picker, .ant-time-picker {
    display: block;
    width: initial;
}

.ant-calendar-cell>.ant-calendar-date:hover {
	background-color: var(--rosa-ine-hover);
}

.ant-calendar-cell>.ant-calendar-date:active {
	background-color: rgba(219, 36, 145);
}

.ant-calendar-selected-date .ant-calendar-date {
	background: var(--rosa-ine);
}

.ant-calendar-selected-date .ant-calendar-date:hover {
	background: rgb(219, 36, 88);
}

.ant-calendar-today .ant-calendar-date {
	background-color: rgba(219, 36, 145, 0.5);
	border-color: var(--rosa-ine);
	color: var(--rosa-ine);
}

.ant-calendar-today .ant-calendar-date:hover {
	background-color: rgba(219, 36, 88, 0.4);
	border-color: rgb(219, 36, 88);
}

.ant-calendar-footer-btn .ant-calendar-ok-btn {
	background-color: var(--rosa-ine);
	border-color: var(--rosa-ine);
	border: 0;
	color: white;
}

.ant-calendar-footer-btn .ant-calendar-ok-btn:hover {
	background-color: var(--rosa-ine-alph);
	border: 0;
	color: white;
}

a.ant-calendar-month-select:hover {
	color: var(--rosa-ine) !important;
}

a.ant-calendar-year-select:hover {
	color: var(--rosa-ine) !important;
}

a.ant-calendar-day-select:hover {
	color: var(--rosa-ine) !important;
}

.ant-calendar-selected-day .ant-calendar-date {
	background-color: rgba(219, 36, 145);
	color: white;
}

.ant-calendar-selected-day .ant-calendar-date:hover {
	background-color: rgb(219, 36, 88);
}

.ant-calendar-month-panel-cell .ant-calendar-month-panel-month:hover {
	background-color: var(--rosa-ine-hover);
	color: #737373;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
	background-color: rgba(219, 36, 145);
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
	background-color: rgba(219, 36, 145);
	background: rgb(219, 36, 88);
	color: white;
}

.ant-calendar-year-panel-decade-select:hover {
	color: rgba(219, 36, 145) !important;
}

.ant-calendar-year-panel-decade-select:hover {
	color: rgba(219, 36, 145) !important;
}

.ant-calendar-year-panel-cell .ant-calendar-year-panel-year:hover {
	background-color: var(--rosa-ine-hover);
	color: #737373;
}

.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
	background-color: rgba(219, 36, 145);
}

.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
	background: rgb(219, 36, 88);
	color: white;
}

.ant-calendar-decade-panel-decade:hover {
	background-color: var(--rosa-ine-hover);
	color: #737373 !important;
}

.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
	background-color: rgba(219, 36, 145);
}

.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
	background-color: rgba(219, 36, 88);
	color: white !important;
}

.ant-calendar-time-picker-select-active li:hover, .ant-time-picker-panel-select li:hover {
	background-color: var(--rosa-ine-hover);
	color: rgba(219, 36, 145);
}

li.ant-calendar-time-picker-select-option-selected:focus, .ant-time-picker-panel-select li:focus {
	color: rgba(219, 36, 145);
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled), .ant-time-picker-input:hover {
	border-color: var(--gris-ine);
}

.ant-calendar-month-panel-header a:hover {
	color: var(--rosa-ine);
}

.tiempo-container .ant-form-item-label {
    line-height: 1;
}

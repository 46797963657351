#seccion{
  margin-bottom: 25px;
}
#titulo-componente{
  color: #7C5BA2;
  font-size: 26px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: 'RobotoSlab-Regular';
}
#buscador-div{
  text-align: right;
  margin-bottom: 20px;
  color: #858585;
}

#buscador-div span{
  font-family: 'Roboto-Regular';
  font-size: 14px;
}
#buscador button{
  color: #000;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0 12px 12px 0;
  background: #fff;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  height: 31px;
  padding-top: 0;
  padding-bottom: 0;
}
#buscador input{
  border-radius: 12px 0px 0px 12px;
}
#icono-ayuda{
  width: 13px;
  height: 12px;
  margin-left: 7px;
}
#div-btn-descargar{
  margin-top: 25px;
}
.btnDescargarAct{
  float: right;
  margin-bottom: 20px;
  margin-top: 45px;
  background-color: #D3097F;
  border-radius: 10px;
  width: 118px;
  height: 34px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.btnDescargarAct:hover{
  background-color: #D3097F;
  color: #fff;
  border-color: #D3097F;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.btnDescargarAct:focus{
  background-color: #D3097F;
  color: #fff;
  border-color: #D3097F;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.btnDescargarDes{
  float: right;
  margin-bottom: 20px;
  /*margin-top: 45px;*/
  background-color: #F4F3F4;
  border-radius: 10px;
  width: 118px;
  height: 34px;
  color: #6D6D6D;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.btnDescargarDes:hover{
  background-color: #F4F3F4;
  color: #6D6D6D;
  border-color: #F4F3F4;
  color: #6D6D6D;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.btnDescargarDes:focus{
  background-color: #F4F3F4;
  color: #6D6D6D;
  border-color: #F4F3F4;
  color: #6D6D6D;
  font-size: 14px;
  font-family: "Roboto-Medium";
}
.modalGafete .ant-modal-content .ant-modal-body{
  padding: 0px;
  height: 407px;
  background-image: url('../../assets/img/fondo_gafete.png');
  background-size: cover;
}
.modalGafete .ant-modal-content .ant-modal-footer{
  display: none;
}
#tabla-volun-seleccionados span svg,
#resultado-voluntarios span svg{
  color:#000;
  width: 20px;
  height: 20px;
}
#resultado-voluntarios ul li svg,
#tabla-volun-seleccionados ul li svg{
  width: 11px;
  height: 19px;
}
#resultado-voluntarios ul li a,
#tabla-volun-seleccionados ul li a{
  font-size: 18px;
  font-family: 'Roboto-Light'
}
#eye-icon{
  width: 19px;
  height: 12px;
}
#printer-icon{
  width: 22px;
  height: 17px;
}
.ant-alert-warning svg{
  color:#faad14;
}
#buscador svg{
  color: #714996;
}
#resultado-voluntarios {
  border: 0.5px solid #999999;
  height: 315px;
}
#tabla-volun-seleccionados{
  text-align: end;
  border: 0.5px solid #999999;
  height: 315px;
}
#folios-seleccionados{
  font-weight: bold;
  font-size: 13px;
  font-family: 'Roboto-Bold';
  margin:10px;
}
/*#modal-header{
  background-color: #cacaca;
  text-align: justify;
}
#modal-header h1{
  font-family: 'RobotoSlab-Bold';
  color: #fff;
  margin-top: 30px;
  margin-left: 10px;
  font-size: 31px;
}
#modal-header img{
  margin-top: 14px;
}*/

#folio-principal{
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
}
#nombre-div{
  font-size: 16px;
  text-align: center;
  top: 75px;
  margin-top: 55px;
  position: absolute;
}
.nombreVoluntario{
  margin-left: 10px;
}
#div-qr{
  position: absolute;
  bottom: 35px;
  left: 60px;
}
#qr-voluntario{
  height: 65px !important;
  width: 70px !important;
}
#nombre-div label{
  font-weight: bold;
}
#nombre-div p{
  font-size: 20px;
  font-family: 'Roboto-Bold';
  color: #fff;
  margin-bottom: -16px;
}
#nombre-div #entidad{
  font-size: 14px;
  color: #b9be25;
  font-family: 'Roboto-Regular';
  margin-top: 15px;
}

#nombre-div #jde{
  font-size: 13px;
  font-family: 'Roboto-Regular';
  margin-top: 10px;
}
#nombre-div #img-foto img{
  width: 50px;
  height: auto;
}
#folio-dos{
  margin-left: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}
#vigencia{
  margin-left: 15px;
  font-size: 16px;
  margin-bottom: 15px;
}
#vigencia h2, label {
  font-weight: bold;
}
#vigencia p {
  margin-bottom: 5px;
}
#buscador .ant-input-group {
  width: 80%;
}
.ant-table-pagination{
  display: block;
  text-align: center;
}
#resultado-voluntarios .ant-table-pagination .ant-pagination-item-link svg
#tabla-volun-seleccionados .ant-table-pagination .ant-pagination-item-link svg {
  color : #6D6D6D !important;
}
.titulotablasVol{
  font-family: 'Roboto-Bold';
  font-size: 15px;
  text-align: center;
  padding-top: 9px;
  padding-bottom: 9px;
}
.titulotablasVol span{
  margin-left: 7px;
  color: #7C5BA2 !important;
}
.titulotablasVol svg{
  color: #7C5BA2 !important;
}
.ant-table-thead tr th {
  font-family: 'Roboto-Bold' ;
  font-size: 15px ;
}
.ant-table-tbody tr{
  font-family: 'Roboto-Light' ;
  font-size: 11px ;
}
.ant-pagination-item-active{
  border-color: #fff !important;
}
.ant-pagination-item-active a:hover{
  border-color: #fff;
}
#paginado{
  margin-top: 24px;
}
#paginado svg{
  color: #6D6D6D !important;
}
#inicio-paginado{
  text-align: right;
}
#inicio-paginado svg{
  color: #6D6D6D !important;
}
#tabla-voluntarios-seleccionados .ant-table-tbody > tr > td,
#tabla-voluntarios-bd .ant-table-tbody > tr > td{
  padding: 3px 0px 3px 16px;
}
#tabla-voluntarios-seleccionados .ant-table-filter-column-title ,
#tabla-voluntarios-bd .ant-table-filter-column-title{
    padding: 0px 5px 0px 5px;
}
#msj-exito{
  color: #FFFFFF;
}
.ant-notification-hook-holder,
.ant-notification-notice {
    background: #606060;
    border: 0.5px solid #999999;
    opacity: 1;
    width: 247px;
    height: 73px;
}
.ant-notification-notice-with-icon
.ant-notification-notice-description {
    font-size: 'Roboto-Light';
}
.ant-notification-notice-close {
  display: none;
}
#icono-descarga-exito{
  width: 35px;
  height: 35px;
}
#pocoyo{
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  top: 50px;
  left: 20px;
  display: block;
  width: 124px;
  height: 93px;
  transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
}

/** **/

@media screen and (max-width: 768px) {
#buscador-div {
    text-align: left;
  }
}

@media screen and (max-width: 696px) {
  #resultado-voluntarios {
    height: 350px;
  }
}
@media screen and (max-width: 670px) {
  #tabla-volun-seleccionados {
    height: 350px;
  }
}
@media screen and (max-width: 677px) {
  #resultado-voluntarios {
    height: 400px;
  }
  table tr th.ant-table-selection-column,
  table tr td.ant-table-selection-column {
    padding-right: 0px;
    padding-left: 0px;
    text-align: center;
  }
}
@media screen and (max-width: 658px) {
  #resultado-voluntarios {
    height: 420px;
  }
}
@media screen and (max-width: 596px) {
  #tabla-volun-seleccionados {
    height: 370px;
  }
}
@media screen and (max-width: 576px) {
  #tabla-volun-seleccionados {
    height: 400px;
  }
}

#titulo-componente{
  color: #7C5BA2;
  font-size: 26px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: 'RobotoSlab-Regular';
}

#obligatorio{
  color:#F8455A;
}
#div-titutlo-componente{
    margin-bottom: -50px;
}
/* tabla consulta */
#card-tabla-voluntarios .ant-card-head-title{
  color:#333333;
  font-size: 15px;
  font-family: "Roboto-Bold";
  text-align: center;
}
#editar-voluntario {
  margin-left: 15px;
  margin-right: 15px;
}
#ver-voluntario span svg{
  color :#333333;
  width: 17px;
  height: 15px;
}
#editar-voluntario span svg{
  color :#333333;
  width: 12px;
  height: 13px;
}
#enviar-correo span svg {
  color :#333333;
  width: 15px;
  height: 11px;
}
#tabla-consulta-voluntarios .ant-table-tbody > tr.ant-table-row:hover > td {
  color: #333333;
  font-family: 'Roboto-Light';
  font-size: 11px;
}
#tabla-consulta-voluntarios .ant-table-thead > tr > th,
.ant-table-tbody > tr > td, .ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px;
}
#tabla-consulta-voluntarios .ant-table-tbody tr,
#tabla-consulta-voluntarios .ant-table-cell{
  text-align: center;
}
/* formularios*/

#img-ayuda{
  width: 239px;
  height: 158px;
  display: none;
  position: absolute;
  z-index: 3;
  right: 79px;
  top: -58px;
}

.pestanaRegistro .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#7C5BA2;
}
.botonIzq{
  width: 47px;
  height: 46px;
  float: left;
}
.botonDer{
  width: 47px;
  height: 46px;
  float: right;
}
#genero{
  background-color: #F9B2B2;
  color:#000000;
}
.formularioInput .ant-form-item-label label{
  font-weight: bold;
  font-family: 'Roboto-Regular';
}
.formularioInput .ant-form-item-control-input-content label,
#campo-genero span,
#hablaIndigena span{
  font-weight: normal;
  font-family: 'Roboto-Regular';
}


#texto-check{
  font-family: 'Roboto-Regular';
  font-size: 12px;
  color: #333333;
  text-align: center;
}

.modalVoluntario .ant-modal-footer{
  display: none;
}
/* -------------------- */
@media screen and (max-width: 768px) {
  #img-ayuda {
    right: 18px;
    top: -143px;
  }
}
@media screen and (max-width: 392px) {
  #img-ayuda {
    width: 206px;
    height: 136px;
  }
}

.modalExito .ant-modal-content .ant-modal-footer{
  display: none;
}
#imagen img{
  width: 100%;
}
#texto-exito{
  font-family: Roboto-Regular;
  font-size: 27px;
  color: #333333;
}
#texto-informativo{
  font-family: Roboto-Medium;
  font-size: 18px;
  color: #929292;
}
#boton-aceptar{
  font-family: Roboto-Medium;
  background-color: #D3097F;
  font-size: 16px;
  color: #fff;
  width: 132px;
  height: 40px;
  margin-top: 10px;
}

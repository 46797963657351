@import url('../styles.css');

.ant-radio-wrapper {
    color: var(--negro_SUPyCAP);
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--rosa-ine);
}

.ant-radio-inner::after {
    background-color: var(--rosa-ine);
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--rosa-ine);
}

.ant-radio-group {
    display: block;
    text-align: left;
    padding: 0 10px 0;
}

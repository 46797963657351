.ant-modal-content {
    border-radius: 12px; /*0px*/
    width: auto;
}

.ant-modal-body {
    padding-left: 32px;
    padding-right: 32px;
}

.ant-modal-close-x {
    text-align: right;
    line-height: initial;
    padding: 1em 1em 0 0;
}

.ant-modal-close-x {
    width: initial;
    height: initial;
}

.ant-modal-footer {
    border-top: initial;
    padding-bottom: 40px;
}

.ant-modal-footer>div {
    text-align: center;
}

.ant-modal-footer>div>button {
    width: 23%;
}

.ant-modal-header {
    border-bottom: initial;
    padding-top: 40px;
    border-radius: 12px;
}

.ant-modal-title {
    font-family: 'Roboto-Light';
    font-size: 2em;
}

.ant-modal-footer>div>.ant-btn-primary:focus:first-child, .ant-modal-footer>div>.ant-btn:focus:first-child {
	background-color: var(--gris-ine-guia);
	color: black;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.ant-modal-footer>div>.ant-btn:hover:first-child {
	background-color: var(--gris-ine-fondo);
}

.ant-modal-footer>div>.ant-btn:first-child {
	background-color: white;
	color: black;
	border: 1px solid var(--gris-ine);
}
@import url('../styles.css');
.web{
	display: block;
}
.movil{
	display: none;
}
#carousel-home-web{
	background-color: #fff;
}
.layout.template_externo>.ant-layout-content {
	margin: 0;
	height: auto;
	min-height: auto;
    display: block;
    background-color: white;
}

.layout.template_externo>.ant-layout-content.home {
	background-color: var(--chart-back-ground)
}

.layout.template_externo.ant-layout {
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
}

.layout.template_externo.ant-layout.home {
	background-color: var(--chart-back-ground)
}

.header_style_externo {
    width: 100%;
    padding: 0;
		background-color: white;
		z-index: 100;
    padding: 30px 0px 26px 109px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.header_style_externo>div>a {
    color: black;
    transition: ease color 0.5s;
}


.header_style_externo>div>hr {
    border: none;
    border-left: 2px solid black;
    height: 0.8em;
    width: 0;
    display: inline-block;
    margin: 0 10px;
}

#banda_titulo {
    max-width: 334px;
    min-width: 250px;
    width: 29%;
    height: auto;
}

#banda_titulo img {
    width: 124px;
		height: 50px;
}

.ant-layout-footer.footer_style_externo {
	background-color: #000;  /*var(--gris-ine-inactivo);*/
	width: 100%;
	position: relative;
	height: 72px;
	z-index: 100;
	padding: 20px 3em 24px;
}




/*                                     **
* estilos medidas dispositivos exactas **
**                                    **/

/* dispositivos con medidas 320px x 568px (iphone 5/se)*/
@media screen and (min-width:320px) and (min-height:568px){
	.header_style_externo {
    height: 60px;
	}
	.pantalla_login {
		height: 200px;
	}
	.pantalla_login video {
		height: 200px;
		width: 100%;
	}
	#seccion-sesion {
    margin:0px auto 7px 60px;
	}
	#seccion-registro {
    margin:0px auto 7px 60px;
	}
	.footer_style{
		height: 55px;
	}
	.movil{
    margin-bottom: -29px;
	}

	.grecaptcha-badge {
		 bottom: 30px !important;
	}
	#cau{
		font-size: 11px;
	}
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
	.modalContainer .close {
    margin-top: -36px;
    margin-right: 0px;
	}
	.modalContainer .modal-content {
		width: 72%;
    height: 86%;
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
	}
}

@media screen and (min-width:568px) and (min-height:320px)
and (max-width:568px) and (max-height:320px){
	.header_style_externo {
    height: 60px;
	}
	.pantalla_login {
		height: 200px;
	}
	.pantalla_login video {
		height: 200px;
		width: 100%;
	}
	#seccion-sesion {
    margin: -10px auto 0px 200px;
	}
	#seccion-registro {
    margin: 0px auto 0px 200px;
	}
	#guia-aspirante {
    margin-left: 110px;
		margin-top: 0px;
	}
	.footer_style{
		height: 55px;
	}

	.grecaptcha-badge {
		 bottom: 30px !important;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
	.modalContainer .close {
    margin-top: -36px;
    margin-right: 0px;
	}
	.modalContainer .modal-content {
		width: 72%;
    height: 86%;
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
	}
}
/* ------- */

/* para dispositivos de 360 px x 640px moto g4 galaxy s5*/
@media screen and (min-width:360px) and (min-height:640px){
	.header_style_externo {
    height: 60px;
	}
	.pantalla_login {
    height: 220px;
	}
	.pantalla_login video {
    height: 220px;
    width: 100%;
	}
	#seccion-sesion img,
	#seccion-registro img {
    height: 56px;
    width: 56px;
	}
	.label_div label {
    font-size: 15px;
	}
	.label_div a {
    font-size: 14px;
	}
	#seccion-sesion {
    margin: 0px auto 8px 65px;
	}
	#seccion-registro {
    margin: 0px auto 8px 65px;
	}
	#guia-aspirante {
    margin-top: 0px;
	}
	.footer_style{
		height: 55px;
	}

	#password,#usuario{
		margin-left: -30px;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
	.modalContainer .close {
    margin-top: -36px;
    margin-right: 0px;
	}
	.modalContainer .modal-content {
		width: 72%;
    height: 86%;
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
	}
}

@media screen and (min-width:640px) and (min-height:360px)
and (max-width:640px) and (max-height:360px) {
	.header_style_externo {
		height: 50px;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
	.modalContainer .close {
    margin-top: -36px;
    margin-right: 0px;
	}
	.modalContainer .modal-content {
		width: 72%;
    height: 86%;
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
	}
	#seccion-sesion {
    margin: 0px auto 10px 180px;
	}
	#seccion-registro {
    margin: 0px auto 10px 180px;
	}
	#guia-aspirante {
    margin-left: 155px;
		margin-top: 0px;
	}
}
/* -------- */

/* dispositivos con medidas 375px x 667px (iphone 6/7/8)*/
@media screen and (min-width:375px) and (min-height:667px) {
	#banda_titulo{
		    margin-left: -56px;
	}
	.pantalla_login {
    height: 225px;
	}
	.pantalla_login video {
    height: 225px;
    width: 100%;
	}
	#seccion-sesion img,
	#seccion-registro img {
		height: 65px;
		width: 65px;
	}
	.label_div label {
		font-size: 16px;
	}
	.label_div a {
		font-size: 14px;
	}
	#seccion-sesion {
    margin: 3px auto 5px 60px;
	}
	#seccion-registro {
    margin: 3px auto 5px 60px;
	}

	.footer_style{
		height: 62px;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
}

@media screen and (min-width:667px) and (min-height:375px){
	.header_style_externo {
    height: 60px;
	}
	#seccion-sesion img, #seccion-registro img {
    height: 45px;
    width: 45px;
	}
	.pantalla, .pantalla_login {
    height: 367px;
	}
	.pantalla_login video {
    width: 100%;
    height: 363px;
	}
	#seccion-sesion {
    margin: 0px 0px 10px 30px;
	}
	#seccion-registro {
    margin: 10px 0px 10px 30px;
	}
	.grecaptcha-badge {
     bottom: 0px !important;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
	.modalContainer .close {
    margin-top: -36px;
    margin-right: 0px;
	}
	.modalContainer .modal-content {
		width: 72%;
    height: 86%;
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
	}
}

/* --- fin iphone 6 7 8 --- */


/* dispositivos con medidas 411px x 731px (pixel 2)*/
@media screen and (min-width:411px) and (min-height:731px) {
	#banda_titulo{
    margin-left: -56px;
	}
	.pantalla_login {
    height: 250px;
	}
	.pantalla_login video {
    height: 250px;
    width: 100%;
	}
	#seccion-sesion img,
	#seccion-registro img {
    height: 65px;
    width: 65px;
	}
	.label_div label {
    font-size: 16px;
	}
	.label_div a {
    font-size: 14px;
	}
	.header_style_externo {
		height: 72px;
	}
	#seccion-sesion {
    margin: 0px auto 8px 80px;
	}
	#seccion-registro {
    margin: 0px auto 8px 80px;
	}

	.footer_style{
		height: 62px;
	}

	.grecaptcha-badge {
		 bottom: 40px !important;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
}

@media screen and (min-width:731px) and (min-height:411px){
	.header_style_externo {
		height: 72px;
	}
	.ant-layout-sider {
    position: unset;
	}
	.pantalla, .pantalla_login {
    height: 300px;
	}
	#seccion-sesion {
    margin: -10px 0px 12px 30px;
	}
	#seccion-registro {
    margin: 12px 0px 12px 30px;
	}
	#seccion-sesion img,
	#seccion-registro img {
    height: 50px;
    width: 50px;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
}
/* ------- */

/* dispositivo con medidas 411 x 823 pixel 2xl*/
@media screen and (min-width:411px) and (min-height:823px) {
	#banda_titulo{
    margin-left: -56px;
	}
	.pantalla_login {
    height: 250px;
	}
	.pantalla_login video {
    height: 250px;
    width: 100%;
	}
	#seccion-sesion img,
	#seccion-registro img {
    height: 75px;
    width: 75px;
	}
	.label_div label {
    font-size: 16px;
	}
	.label_div a {
    font-size: 14px;
	}
	#seccion-sesion {
    margin: 6px auto 10px 80px !important;
	}
	#seccion-registro {
    margin: 6px auto 10px 80px;
	}
	#no
	.footer_style {
    height: 62px;
	}
	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
}

@media screen and (min-width:823px) and (min-height:411px) {
	/*.web{
    position: absolute;
    bottom: 0px;
	}*/

	#seccion-sesion {
    margin: 14px 0px 15px 30px;
	}
	#seccion-registro {
    margin: 15px 0px 15px 30px;
	}
	#seccion-sesion img,
	#seccion-registro img {
    height: 50px;
    width: 50px;
	}

	#cau,
	.text_footer,
	.footer-version{
		font-size: 15px;
	}
	#division-footer {
    margin-left: 10px;
	}
	#footer-version {
    margin-top: 10px;
		margin-left: 158px;
	}
}

/* ---- fin pixel 2xl */

/* dispositivos con medidas 414px x 736px (iphone 6/7/8 plus)*/
@media screen and (min-width:414px) and (min-height:736px) {
	#banda_titulo{
		    margin-left: -56px;
	}

	#seccion-sesion img,
	#seccion-registro img {
		height: 65px;
		width: 65px;
	}
	.label_div label {
		font-size: 16px;
	}
	.label_div a {
		font-size: 14px;
	}
	#seccion-sesion {
    margin: 12px auto 12px 80px;
	}
	#seccion-registro {
    margin: 12px auto 12px 80px;
	}
	.footer_style{
		height: 62px;
	}
}

@media screen and (min-width:736px) and (min-height:414px)
and (max-width:736px) and (max-height:414px) {
	.movil {
    margin-top: -20px;
	}
}
/* ------- */

/** Pantallas pequeñas **/

@media screen and (min-width:1024px) and (min-height:600px) {
	.modalContainer .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid lightgray;
    width: 53%;
    height: 86%;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 30px;
    padding-right: 30px;
	}
	.header_style_externo {
    height: 60px;
	}

	#seccion-sesion img,
	#seccion-registro img {
    height: 65px;
    width: 65px;
	}
	#seccion-sesion {
		margin: 40px auto 25px 60px;
	}
	#seccion-registro {
		margin: 25px auto 25px 60px;
	}
	.pantalla video {
    width: 100%;
    height: 467px;
	}

	#password,
	#usuario{
		width: 260px;
	}
	.grecaptcha-badge {
    bottom: 84px !important;
	}
	#division-footer {
    margin-left: 50px !important;
	}
	#guia-aspirante {
    margin-left: 50px;
	}
}

/** -------------------   */
/* dispositivos con medidas 1024px x 768px (ipad)*/

@media screen and  (max-width:1024px) and (min-height:768px){
	.modalContainer .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid lightgray;
    width: 53%;
    height: 86%;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 30px;
    padding-right: 30px;
	}
	.header_style_externo {
    height: 120px;
	}

	#seccion-sesion {
    margin: 48px auto 15px 60px;
	}
	#seccion-registro {
    margin: 20px auto 15px 60px;
	}
	.pantalla video {
		width: 100%;
		height: 585px;
	}

	#password,
	#usuario{
		width: 260px;
	}
	.grecaptcha-badge {
		bottom: 84px !important;
	}

	.ant-layout-sider-children, .side_registro {
    margin-top: 45px;
	}
	#division-footer {
    margin-left: 50px !important;
	}
}

@media screen and  (max-width:768px) and (min-height:1024px){

}
/****** ****/

/* dispositivos con medidas 375px x 812px (iphone X)*/
@media screen and (min-width:375px) and (min-height:812px) {
	#banda_titulo{
		    margin-left: -56px;
	}

	#seccion-sesion img,
	#seccion-registro img {
		height: 70px;
		width: 70px;
	}
	.label_div label {
		font-size: 16px;
	}
	.label_div a {
		font-size: 15px;
	}
	#seccion-sesion {
    margin: 5px auto 20px 70px;
	}
	#seccion-registro {
    margin: 5px auto 20px 70px;
	}
	.footer_style{
		height: 62px;
	}

}

@media screen and (min-width:812px) and (min-height:375px) {
	#division-footer{
    margin-left: 10px;
	}
	#footer-version{
		margin-top: 10px;
		margin-left: 145px;
	}
}
/* ------- */

/* Pantalla mediana 1280px 720px */
@media screen and (min-width: 1280px)  and (min-height:720px){
	#no-correo-seccion {
    width: 372px;
	}
	#guia-aspirante {
    margin-left: 95px;
	}
	.header_style_externo {
    height: 120px;
	}

	#seccion-sesion img,
	#seccion-registro img {
    height: 70px;
    width: 70px;
	}
	#seccion-sesion {
    margin: 40px auto 30px 60px !important;
	}
	#seccion-registro {
    margin: 30px auto 30px 60px;
	}

	#no-correo-seccion {
    margin-left: 59px;
    height: 38px;
    margin-top: 0px;
	}
	#guia-aspirante{
		font-size: 18px;
		margin-left: 85px;
	}
	#img-info{
		height: 18px;
		width: 18px;
	}
	#division-footer {
    margin-left: 50px;
	}
}
 /** ----------------- */

 /*pantallas con medianas 1366px x 768px */
 @media screen and (min-width: 1366px)  and (min-height:768px){

	#seccion-sesion img,
	#seccion-registro img {
    height: 65px;
    width: 65px;
	}
	.label_div label {
    font-size: 18px;
	}
	.label_div a {
    font-size: 16px;
	}
	#seccion-sesion {
    margin: 50px auto 35px 60px !important;
	}
	#seccion-registro {
    margin: 35px auto 35px 60px;
	}
 }
 /* -------------- */

 /*pantallas con medianas 1440px x 900px */
 @media screen and (min-width: 1440px)  and (min-height:900px){
	#seccion-sesion img,
	#seccion-registro img {
		 height: 85px;
		 width: 85px;
	}
	.label_div label {
		 font-size: 20px;
	}
	.label_div a {
		 font-size: 18px;
	}
	#seccion-sesion {
		 margin: 90px auto 40px 60px !important;
	}
	#seccion-registro {
		 margin: 40px auto 40px 60px;
	}

	.text_footer, .footer-version {
    font-size: 15px;
	}
 }
 /* ------------- */

/*pantallas con medianas 1519px x 754px */
@media screen and (min-width: 1536px)  and (min-height:722px){

	.footer_style_externo {
    height: 84px !important;
	}

	#no-correo-seccion {
    margin-top: 17px;
    width: 426px;
	}
	#guia-aspirante {
    margin-left: 105px;
	}
	#no-correo-seccion {
    width: 422px;
	}
	#guia-aspirante {
    margin-left: 128px;
	}
}

/*pantallas con medianas 1920px x 937px */
@media screen and (min-width: 1920px)  and (min-height:937px){
	#seccion-sesion {
		 margin: 90px auto 40px 120px !important;
	}
	#seccion-registro {
		 margin: 40px auto 40px 120px;
	}
}

/* ------------------- */
@media screen and (min-width:1920px) and (min-height:1080px) {
	.modalContainer .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid lightgray;
    width: 60%;
    height: 60%;
    padding-top: 70px;
    padding-bottom: 0px;
    padding-left: 40px;
    padding-right: 40px;
	}
	#banda_titulo img {
    width: auto;
    height: 60px;
	}

	#seccion-sesion img,
	#seccion-registro img {
    height: 120px;
    width: 120px;
	}
	.label_div label {
    font-size: 28px;
	}
	.label_div a {
    font-size: 24px;
	}
	#img-info{
		height: 22px;
		width: 22px;
		margin: 4px 4px 3px 0px;
	}
	#seccion-sesion {
		 margin: 100px auto 47px 80px !important;
	}
	#seccion-registro {
		 margin: 47px auto 47px 80px;
	}

	.text_footer {
    font-size: 15px;
	}
	#logoINE {
    width: 130px;
    height: 41px;
	}

}

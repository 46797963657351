@import url('../styles.css');

#seccion{
    margin-bottom: 25px;
}

#titulo-componente{
    color: #8c7ba2;
    font-size: 26px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-family: 'Roboto-Regular';
}

#tabla-consulta-casillas{
    text-align: center;
}

#edit-icon svg,
#deleted-icon svg,
#eyecc-icon svg{
  color:#494845;

}

#filter-icon svg{
    color:#663C99;
}

#modal-header{
    font-family: 'Roboto-Regular';
    background-color: #fff;
    text-align: justify;
}

#modal-header h1{
    font-family: 'RobotoSlab-Bold';
    color: #7C5BA2;
    margin-top: 30px;
    margin-left: 10px;
    font-size: 30px;
}

#body-modal{
    font-family: 'Roboto-Regular';
    color:#333333;
    margin-left: 10px;
    margin-top: 25px;
}

#folio-casilla{
    font-family: 'Roboto-Regular';
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 10px;
}

.tab-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:#673C99 !important; 
    font-size: 16px;
    border-bottom: 5px solid;
}

.tab-modal .ant-tabs-ink-bar {
    position: absolute;
    background:#673C99;
    pointer-events: none;
}


.Modal-consulta .ant-modal-content{
    border: 5px solid #D3097F;
    width: 1000px;
    height: 680px;
}

.modal-voluntarios .ant-modal-content{
    text-align: center;
    width: 400px;
    height: 300px;
}

.tablaDispositivos{
	text-align: center;
	top: 10px;
	left: 10px;
	width: 420px;
	height: 50px;
	border: 0.5px solid #e2dfdf;
	opacity: 1;
}

/*table.tablaDispositivos > tbody > tr > td{
    padding: 0px;
}*/

.tablaCabezal {
    text-align: center;
    top: 331px;
    left: 206px;
    width: 400px;
    height: 50px;
    border: 0.5px solid #e2dfdf;
    opacity: 1;			
}

.titulo-modifica{
    font-size: 30px;
    font-family: Roboto-Light;
    color:#1D1D1B;
    text-align: center;
}
  
.notificacion-modifica{
    font-size: 18px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Regular;
    color:#575756;
    
}

.modalModificaConsulta .ant-modal-content{
    text-align: center;
    width: 327px;
    height: 490px;
  }
  
#flechaDer .ant-btn .ant-btn-primary{
    width: auto;
    height: auto;
}
  
  
#flechaIzq .ant-btn{
    width: auto;
    height: auto;
}

#img-modifica-modal{
    width: 241px;
    height: 231px;
}

#img-elimina-modal{
    width: 260px;
    height: 240px;
}

.modalElimina .ant-modal-content{
    text-align: center;
    width: 327px;
    height: 500px;
}

.notificacion-elimina{
    font-size: 23px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Light;
    color:#575756;
    
}

#img-fechasmodalModifica{
    width: 40px;
    height: 40px;
}
  
.modalFechasModifica .ant-modal-content{
    text-align: center;
    width: 300px;
    height: 200px;
}
  
.titulo-fechasModifica{
    font-size: 18px;
    font-family: Roboto-Light;
    color:#1D1D1B;
    text-align: center;
}


#img-popFechasModifica{
    width: 35px;
    height: 28px;
  }
  
  
.cuerpo-contentModifica{
    font-size: 16px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Light;
    color:#000000;
    
}
  
.confirmModifica{
    font-size: 16px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Light;
    color:#D3097F;
}

.tituloTablaCasillas{
    font-size: 15px;
    text-align: center;
    justify-content:center;
    font-family: Roboto-Bold;
    color:#333333;
}

.div-boton-modifica1  button.ant-btn{
    color:#FFFFFF;
    background-color: #D3097F;
    
}
  
.div-boton-modifica1 button.ant-btn:hover{
    color:#FFFFFF;
    background-color: #D3097F;
}
  
.div-boton-modifica1 button.ant-btn:disabled{
    background-color:#F4F3F4;
    color:#6D6D6D;
}

  
.div-boton-modifica2  button.ant-btn{
    color:#FFFFFF;
    background-color: #D3097F;
    
}
  
.div-boton-modifica2 button.ant-btn:hover{
    color:#FFFFFF;
    background-color: #D3097F;
}
  
.div-boton-modifica2 button.ant-btn:disabled{
    background-color:#F4F3F4;
    color:#6D6D6D;
}
  
.div-boton-modifica3  button.ant-btn{
    color:#FFFFFF;
    background-color: #D3097F;
    
}
  
.div-boton-modifica3 button.ant-btn:hover{
    color:#FFFFFF;
    background-color: #D3097F;
}
  
.div-boton-modifica3 button.ant-btn:disabled{
    background-color:#F4F3F4;
    color:#6D6D6D;
}
  
.boton-anadirModifica  button.ant-btn{
    color:#FFFFFF;
    background-color: #D3097F;
    
}
  
.boton-anadirModifica button.ant-btn:hover{
    color:#FFFFFF;
    background-color: #D3097F;
}
  
.boton-anadirModifica button.ant-btn:disabled{
    background-color:#F4F3F4;
    color:#6D6D6D;
}

.casillaConsulta{
    font-size: 26px;
    text-align: center;
    justify-content:center;
    font-family: RobotoSlab-Regular;
    color:#6D6D6D;
}

.tabla-casillas thead[class*="ant-table-thead"] th{
    background-color:#fff !important;
}


/****Seguimiento de casillas****/
.icono {
    font-size: 15px !important;
    color:black;
}
.icono:hover{
    color: var(--rosa-ine);
    font-size: 15px !important;
}
.iconoDisabled{
    color: var(--gris-ine-inactivo);
    font-size: 15px !important;
}

.div_estatus_seguimiento{
    text-align: center;
    padding: 2% 0;
}

#lineaDivision{
    border: 1px solid #868686;
    background-color: #868686;
}

.modal_descripcion_accion {
    font-size: 32px;
    color: #1D1D1B;
    text-align: center;
    font-weight: normal;
}
.modal_informacion_extra{
    font-size: 18px;
    color: #575756;
    text-align: center;
    font-weight: normal;
}

.btnBorder{
    background-color: #fff !important;
    color: #000000 !important;
    border: 2px solid #707070 !important;
    margin-top: 25px !important;
	width: 130px !important;
	height: 35px !important;
    border-radius: 6px !important;
    font-size: 14px !important;
}

#btnRegistroNuevo:hover {
    border: 1px solid var(--rosa-ine) !important;
}

#btnRegistroNuevo:hover span {
    font-size: 14px;
    color: var(--rosa-ine);
}

/*Collapse - casillas itinerantes*/
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    background: var(--rosa-ine) !important;
    height: 45px;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  /*margin-bottom: 24px;*/
  overflow: hidden;
  border: 0px;
  border-radius: 2px;
}

#guardar-asistencia{	
	margin-bottom: 20px;
	margin-top: 45px;
	background-color: #D3097F;
	font-family: 'RobotoSlab-Regular';
	border-radius: 10px;
	width: 118px;
	height: 34px;
  }

  #guardar-asistencia:hover{
	background-color: #F4F3F4;
	font-family: 'RobotoSlab-Regular';
  }
  #guardar-asistencia:hover span{
	color: #6D6D6D;
	font-family: 'RobotoSlab-Regular';
  }
  #guardar-asistencia span{
	color: #fff;
	font-size: 14px;
	font-family: 'RobotoSlab-Regular';
  }
.header-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #333333;
	height: 100px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	padding: 0px 50px 0px 50px;
	box-shadow: 0px 3px 4px #00000026;

	.hc-sistema {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		column-gap: 20px;

		.hc-sistema-link {
			.hc-sistema-link-image{	
				width: 70px;
				height: auto;
			}
		}

		.hc-sistema-title {
			font-family: Roboto-Regular;
			font-size: 42px;
			color: #FFFFFF;
		}
	}

	.hc-datos-usuario {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
		align-items: center;
		padding: 5px;
		row-gap: 5px;
		column-gap: 15px;

		.hc-datos-usuario-image {
			width: 25px;
			height: auto;
			align-self: center;
		}

		.hc-datos-usuario-title {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			color: #FFFFFF;
			padding-right: 10px;
			border-right: 2px solid var(--rosa-ine);

			.hc-datos-usuario-title-name {
				font-family: Roboto-Regular;
				font-size: 13px;
			}

			.hc-datos-usuario-title-nivel{
				float: left;
				font-family: Roboto-Regular;
				color: #D3097F;
				font-size: 8px;
				font-weight: bold;
			}
		}
		
		.hc-datos-usuario-logout {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			column-gap: 5px;
			font-family: Roboto-Regular;
			font-size: 13px;
			color: #FFFFFF;
		}

	}
}

@media (max-width: 700px) {
    .header-container {
		padding-left: 10px;
		padding-right: 10px;

		.hc-sistema {
			.hc-sistema-title {
				font-size: 10px !important;
			}
		}
	}
}

@media (max-width: 400px) {
    .header-container {
		padding: 2px;

		.hc-sistema {
			.hc-sistema-title {
				display: none;
			}
		}
	}
}
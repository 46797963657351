.footer-container {
    display: grid;
    grid-template-columns: 100px 1fr 3fr 1fr;
    align-content: center;
    justify-items: center;
    padding: 5px 30px;
	background-color:#7f8688;
	width: 100%;
	height: 50px;
	position: fixed;
	bottom: 0px;

    .fc-image {
        width: 100px;
        height: auto;
    }

    .fc-text {
        align-self: center;
        text-align: center;
        font-size: 10px;
        font-family: MyriadPro-Regular;
        color: #B6B6B6;
    }

    .fc-text-v {
        align-self: center;
        text-align: center;
        font-size: 8px;
        font-family: MyriadPro-Regular;
        color: #7f8688;
    }

}

@media (max-width: 500px) {
    .footer-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 10px;
        justify-content: space-around;
        padding: 5px 5px;

        .fc-image {
            width: 100px;
            padding: 5px;
        }
    }
}
.home-card-list {
    display: grid;
    width: 100%;
    overflow-y: scroll;
    height: calc(100vh - 200px);
    margin-top: 150px;
    padding: 10px 30px;
    grid-template-columns: 400px 1.3fr 1fr;
    grid-template-rows: 240px minmax(0, 1fr) 5px;
    column-gap: 5px;
    row-gap: 5px;
    grid-template-areas: 
        "casillas       participantesEdad   participantesEdad"
        "voluntarios    voluntarios         participantesSexo";
    justify-items: stretch;
    align-items: stretch;
    align-self: center;
    line-height: 1.15;

    @media (max-width: 1150px) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: fit-content !important;
        padding: 10px 20px 70px 20px;
    }

    @media screen and (min-width: 1025px) and (min-height: 800px) {
        padding: 1.32vh 1.95vw;
        grid-template-columns: 26vw 1.3fr 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 0.32vw;
        row-gap: 0.66vh;
    }

    @media (max-height: 750px) {
        grid-template-rows: 240px 270px 5px;
    }

}

.home-card-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20px auto;
    margin: 5px;
    padding: 10px;
    justify-items: stretch;
    align-items: stretch;
    border: none;
    background-color: white;

    @media screen and (min-width: 1025px) and (min-height: 800px) {
    	grid-template-rows: 2.65vh auto;
        margin: 0.66vh 0.32vw;
        padding: 1.32vh 0.65vw;
    }

    @media (max-width: 500px) {
        grid-template-rows: auto;
    }

    .hcdc-header{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-items: flex-start;
        align-content: flex-start;
        align-items: flex-start;

        .hcdc-title {
            font-family: Roboto-Bold;
            font-size: 18px;
            color: var(--chart-text);

            @media screen and (min-width: 1025px) and (min-height: 800px) {
              font-size: 2.38vh;
			}
        }
    
        .hcdc-subtitle {
            font-family: Roboto-Regular;
            font-size: 10px;
            color: var(--chart-text);

            @media screen and (min-width: 1025px)and (min-height: 800px) {
                font-size: 1.32vh;
			}
        }
    }
}

.home-card-casillas {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.home-chart-casilla {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 180px;
    justify-items: stretch;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    overflow: hidden;

    @media screen and (min-width: 1025px) and (min-height: 800px) {
        grid-template-rows: 23.87vh;
	}

    @media (max-width: 420px) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        row-gap: 10px;
        height: 300px;
    }

    .hchc-etiqueta {
        display: flex;
        width: 70%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        justify-self: center;
        row-gap: 10px;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
            row-gap: 1.32vh;
		}

        @media (max-width: 420px) {
            display: flex;
            width: 100px;
        }

        .hchc-label {
            font-family: Roboto-Light;
            font-size: 12px;
            color: var(--chart-text);

            @media screen and (min-width: 1025px) and (min-height: 800px) {
              font-size: 1.59vh;
			}
        }
    }

    .hchc-data {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-items: stretch;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;
        width: 180px;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
          width: 11.71vw;
		}

        .hchc-grafica-container {
            width: 100px;
            height: auto;
            align-self: center;

            @media screen and (min-width: 1025px) and (min-height: 800px) {
                width: 6.51vw;
			}
    
            .hchc-grafica {
                width: inherit;
                height: inherit;
            }
        }

        .hchc-estatusCasillaValue {
            display: grid;
            grid-template-columns: 40% 60%;
            grid-template-rows: 1fr;
            justify-content: space-around;
            font-family: RobotoSlab-Bold;
            font-size: 16px;

            @media screen and (min-width: 1025px) and (min-height: 800px) {
                font-size: 2.12vh;
			}

            @media (max-width: 420px) {
                grid-template-columns: 50% 50%;
            }
        }

        .hchc-estatusCasillaLabel {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 1fr;
            justify-content: space-around;
        }
    }
    
}

.home-card-participantes-edad {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: stretch;
    align-items: center;

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .hcdpe-header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-items: center;
        justify-content: center;
        align-content: center;
        align-self: center;
        text-align: center;

        .hcdpe-subtitle {
            font-family: Roboto-Light;
            font-size: 24px;
            color: var(--chart-text);

            @media screen and (min-width: 1025px) and (min-height: 800px) {
              font-size: 3.18vh;
			}

            @media (max-width: 700px) {
                font-size: 14px;
            }
        }

        .hcdpe-total {
            padding-bottom: 10px;
			font-size: 56px;
            font-family: Roboto-Bold;
            color: var(--chart-bar3);

            @media screen and (min-width: 1025px) and (min-height: 800px) {
                padding-bottom: 1.32vh;
                font-size: 7.42vh;
			}

            @media (max-width: 300px) {
                font-size: 30px;
            } 
        }

        .hcdpe-grafica-container {
            @media (max-width: 700px) {
                padding-left: 30px;
            }
        }
    }
}

.home-card-voluntarios {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 5px;

    @media screen and (min-width: 1025px) and (min-height: 800px) {
      margin-bottom: 0.66vh;
	}

    .hcdv-totales {
        align-self: flex-end;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 180px minmax(0, 1fr) 30px;
		column-gap: 5px;
		height: 30px;
        justify-items: flex-start;
        align-items: center;
        margin-bottom: 8px;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
            grid-template-columns: 11.71vw minmax(0, 1fr) 1.95vw;
            column-gap: 0.32vw;
            height: 3.97vh;
            margin-bottom: 1.06vh;
		}

        @media (max-width: 400px) {
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            align-items: flex-end;
            height: unset;
        }

        .hcdv-titulo {
            font-family: Roboto-Bold;
            font-size: 15px;
            text-align: right;
            color: var(--chart-text);

            @media screen and (min-width: 1025px) and (min-height: 800px) {
              font-size: 1.98vh;
			}

            @media (max-width: 400px) {
                font-size: 11px;
            }
        }

        .hcdv-value {
            font-family: Roboto-Bold;
            font-size: 43px;
            color: var(--chart-bar1);

            @media screen and (min-width: 1025px) and (min-height: 800px) {
                font-size: 5.7vh;
			}
        }

        .hcdv-img {
            width: 14px;
            justify-self: center;

            @media screen and (min-width: 1025px) and (min-height: 800px) {
                width: 0.91vw;
			}
        }

    } 
}

.home-chart-voluntario {
    display: grid;
    grid-template-columns: 100px minmax(0, 1fr) 200px;
    grid-template-rows: auto;
    justify-items: start;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;

    @media screen and (min-width: 1025px) and (min-height: 800px) {
       grid-template-columns: 6.51vw minmax(0, 1fr) 13.02vw;
	}

    @media (max-width: 920px) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 20px;
        row-gap: 40px;
    }

    .hchv-title {
        font-family: Roboto-Bold;
        font-size: 18px;
        color: var(--chart-text);
        justify-self: center;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
            font-size: 2.38vh;
		}
    }

    .hchv-grafica {
        width: 100%;
        height: 100%;
        max-height: 100px;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
            max-height: 13.26vh; 
		}
    }

    .hchv-estatusVoluntario {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-self: flex-end;
        align-self: center;
        row-gap: 2px;
        margin: 0px 40px 0px 20px;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
            row-gap: 0.26vh;
            margin: 0px 2.6vw 0px 1.3vw;
		}

        @media (max-width: 920px) {
            align-self: flex-start;
            margin: 0px 0px 0px 5px;
        }
    }
}

.home-card-participantes-sexo{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    
    @media (max-width: 920px) {
        flex-wrap: wrap;
    }

}

.home-label-container {
    display: grid;
    grid-template-columns: 10px 85px minmax(0, 1fr);
    column-gap: 5px;
    font-size: 11px;
    grid-template-rows: auto;
    align-items: center;
    font-family: Roboto-Regular;
    color: var(--chart-text);

    @media screen and (min-width: 1025px) and (min-height: 800px) {
        grid-template-columns: 0.65vw 5.53vw minmax(0, 1fr);
        column-gap: 0.32vw;
        font-size: 1.45vh;
	}

    .hlc-label {
        justify-self: start;
    }

    .hlc-value {
        justify-self: end;
    }
    
    .hlc-square {
        width: 10px;
        height: 10px;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
	        width: 0.65vw;
            height: 1.32vh;
   		}
    }

}

.home-label-image-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
    margin: 20px 0px 5px 0px;

	@media screen and (min-width: 1025px) and (min-height: 800px) {
        padding: 0vmin 0.65vw 0vmin 0.65vw;
        margin: 2.65vh 0vmin 2.65vh 0vmin;
   	}
    

    .hlic-image {
        display: flex;
        position: relative;
        justify-self: stretch;
        align-self: stretch;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;

        @media (max-width: 1300px) {
            padding-top: 10px;
        }

        .hlic-img {
            height: 60px;

            @media (max-width: 1300px) {
                height: 60px;
            }

            @media screen and (min-width: 1025px) and (min-height: 800px) {
                height: 12.33vh;
    		}
        }

        .hlic-text {
            font-family: RobotoSlab-Bold;
            font-size: 90px;

            @media (max-width: 1300px) {
                font-size: 70px;
            }

            @media screen and (min-width: 1025px) and (min-height: 800px) {
            	font-size: 11.93vh;
    		}
        }

        .hlic-tooltip {
            display: none;
            bottom: 100%;
            min-width: max-content;
            font-size: 13px;
            background-color: var(--chart-back-ground);
            color: #000000;
            text-align: center;
            padding: 5px 10px 5px 10px;
            border-radius: 20px;
            position: absolute;
            z-index: 1;

            @media screen and (min-width: 1025px) and (min-height: 800px) {
            	font-size: 1.72vh;
        		padding: 0.66vh 0.65vw 0.66vh 0.65vw;
            	border-radius: 2.65vh;
    		}
        }

        &:hover {
            .hlic-tooltip {
                display: flex; 
            }
        }
    }

    .hlic-label {
        font-family: Roboto-Bold;
        font-size: 14px;
        text-align: center;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
        	font-size: 1.85vh;
    	}
    }

    .hlic-porcentaje {
        font-family: Roboto-Bold;
        font-size: 40px;

        @media screen and (min-width: 1025px) and (min-height: 800px) {
        	font-size: 7.55vh;
    	}
    }
}

.hcard-borde .ant-card-body{
    padding: 0px;
    width: 420px;
    background-color: transparent;
    margin-top: -30px;
}

.ant-carousel .slick-dots li button {
    background: #333333 !important;
}

.img-logo-home{
    height: 191px;
    width: 400px;
}

.centrar-img-home{
    display: block;
    margin-right: auto;
    margin-left: auto;
}


@import url('../styles.css');

.ant-select-auto-complete.ant-select .ant-input:focus {
    border-color: var(--gris-ine);
}

.has-error .ant-input, .has-error .ant-input:hover {
    background-color: transparent;
}

.ant-select-auto-complete.ant-select .ant-input:focus, .ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: var(--gris-ine);
}

.autocomplete-container {
	height: auto;
	text-align: left;
	display: flex;
	flex-direction: column-reverse;
}
